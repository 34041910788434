import { Middleware } from '@nuxt/types';
import { usePageStore } from '~/stores/page';
import { Logger } from '~/helpers/logger';
import { RoutableInterface } from '~/modules/GraphQL/types';
import axios from 'axios';

const brandUrlResolverMiddleware : Middleware = async (context) => {
  const pageStore = usePageStore();
  const { path } = context.route;

  const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '');

  // Extract only the numeric part
  const numericPart = clearUrl.match(/\d+$/)?.[0] || null;
  Logger.debug('middleware/url-resolver', clearUrl);

 

  const response = await axios.get(`${process.env.VSF_MAGENTO_BASE_URL}/${context.app.i18n.locale}/rest/V1/mpbrand/public`,{
    headers: {
        'Content-Type': 'application/json',
    }
});

    console.log("Reading on middleare",response.data)
const info_find = response.data.find((item) => item.id == numericPart);

if(!info_find){
    context.error({ statusCode: 404 });
}


//   Logger.debug('middleware/url-resolver/result', { data, errors });

//   const results: RoutableInterface | any = data?.route ??  null;

//   if (!results || errors?.length) context.error({ statusCode: 404 });


//   pageStore.$patch((state) => {
//     state.routeData = results;
//   });
};

export default brandUrlResolverMiddleware;
