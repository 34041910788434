import { readonly, ref, useContext, useFetch } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { placeOrderCommand } from '~/modules/checkout/composables/useMakeOrder/commands/placeOrderCommand';
import useCart from '~/modules/checkout/composables/useCart';
import type { PlaceOrderOutput } from '~/modules/GraphQL/types';
import { ComposableFunctionArgs, useCartView, useConfig, useUiNotification, useUser } from '~/composables';
import type { UseMakeOrderErrors, UseMakeOrderInterface } from './useMakeOrder';
import axios from 'axios';
import { storeWiseCurrency } from '~/utils/HomePageIdentifiers';

/**
 * Allows making an order from a cart.
 *
 * See the {@link UseMakeOrderInterface} for a list of methods and values available in this composable.
 */
export function useMakeOrder(): UseMakeOrderInterface {
  const loading = ref(false);
  const error = ref<UseMakeOrderErrors>({ make: null });
  const { cart } = useCart();
  const {config} = useConfig();
  const context = useContext();
  const cartView = useCartView();
  const { send: sendNotification } = useUiNotification();
  const {isAuthenticated} = useUser();

  const make = async (params?: ComposableFunctionArgs<{}>): Promise<PlaceOrderOutput | null> => {
    Logger.debug('useMakeOrder.make');
    let placedOrder = null;
    try {
      loading.value = true;
      placedOrder = await placeOrderCommand.execute(
        context,
        cart.value.id,
        params,
      );
      error.value.make = null;
    } catch (err) {
      error.value.make = err;
      Logger.error('useMakeOrder.make', err);
    } finally {
      loading.value = false;
    }

    return placedOrder;
  };

  const tabbyCheckout = async (orderId) => {


    console.log("cartView.totals.value.total ---->",cartView.totals.value.total)
    



    loading.value = true;


    const response = await axios.post('/tabby-checkout', {

        "lang": "en",
        "merchant_code": "ksa",
        "merchant_urls": {
          "success": process.env.VSF_STORE_REDIRECT_URL+ (context.app.i18n.locale !== context.app.i18n.defaultLocale ?( '/' +  context.app.i18n.locale + '/') : '/') + 'thank-you' + '?callback=tabby-success',
          "cancel": process.env.VSF_STORE_REDIRECT_URL + (context.app.i18n.locale !== context.app.i18n.defaultLocale ?(  '/' + context.app.i18n.locale + '/') : '/') + 'failed-order' + '?callback=tabby-cancelled',
          "failure": process.env.VSF_STORE_REDIRECT_URL + (context.app.i18n.locale !== context.app.i18n.defaultLocale ?( '/' +  context.app.i18n.locale + '/') : '/') + 'failed-order' + '?callback=tabby-failed',
        },
        "payment": {
          "amount": cartView.totals.value.total,
          "currency": context.app.$vsf.$magento.config.state.getCurrency() || storeWiseCurrency(context.app.i18n.locale),
          // "currency": "AED",

          "description": "",
          "buyer": {
            "phone": cart.value.billing_address.telephone,
            "email": cart.value.email,
            "name": cart.value.billing_address.firstname + " " + cart.value.billing_address.lastname,
            "dob": null
          },
          "order": {
            "tax_amount": 15.19,
            "shipping_amount": 0,
            "discount_amount": 0,
            "reference_id": orderId,
            "items": cartView.products.value.map((item) => ({
              "title": item.product.name,
              "quantity": item.quantity,
              "unit_price": item.prices.row_total.value,
              "tax_amount": item.prices.row_total_including_tax.value - item.prices.row_total.value,
              "reference_id": item.uid,
              "image_url": item.product.thumbnail.url,
              "product_url": item.product.thumbnail.url
            })),
          },
          
          "shipping_address": {
            "city": cart.value.shipping_addresses.length > 0 ? cart.value.shipping_addresses[0].city : "",
            "address": cart.value.shipping_addresses.length > 0 ?cart.value.shipping_addresses[0].street[0] + ", " + cart.value.shipping_addresses[0].city + " " + cart.value.shipping_addresses[0].city + ", " + cart.value.shipping_addresses[0].country.label : "",
            "zip": cart.value.shipping_addresses.length > 0 ? cart.value.shipping_addresses[0].postcode : "",
          },
          "order_history": []
        },
        "product_type": "installments",
        "isAuthenticated":isAuthenticated.value,
        "cart_id":cart.value.id,
        "rest_url": `/rest/${context.app.i18n.locale}/V1`
    });

    if(response.data?.status == 200){
      setTimeout(() => {
        loading.value = false;
          
        }, 2000);
      window.location.href = response.data.url
      console.log("response.data ---->",response.data)
      
    } else {

      setTimeout(() => {
        loading.value = false;
          
        }, 1000);

        sendNotification({
          icon: 'error',
          id: Symbol(`subscription-error`),
          message: "Try again later something went wrong !!",
          persist: false,
          title: 'error',
          type: 'danger',
        })
    }

    
  }

  const tamaraCheckout = async (orderId) => {

  
      



    loading.value = true;



    try {
        const { data } = await context.app.$vsf.$magento.api.customQuery({
            query: `
            query TamaraplaceOrderQuery($orderId: String!) {
                TamaraplaceOrder(orderId: $orderId) {
                    success
                    orderId
                    redirectUrl
                    error
                }
            }
            `,
            queryVariables: {
                orderId, // Dynamically pass the orderId here
            },
        }) as any;

        if (data?.TamaraplaceOrder?.redirectUrl) {
            window.location.href = data?.TamaraplaceOrder?.redirectUrl;
        } else {
            sendNotification({
                icon: 'error',
                id: Symbol(`subscription-error`),
                message: "Try again later something went wrong !!",
                persist: false,
                title: 'error',
                type: 'danger',
            });
            return 'response';
        }
    } catch (error) {
        sendNotification({
            icon: 'error',
            id: Symbol(`subscription-error`),
            message: "An error occurred while processing the request.",
            persist: false,
            title: 'error',
            type: 'danger',
        });
        return 'response';
        console.error("Tamara Checkout Error: ", error);
    } finally {
        loading.value = false;
    }
};


const telrCheckout = async (orderNumber) => {
  loading.value = true;
  const telr_response = await axios.post('/telr-checkout', {
    "method": "create",
    "store": (config.value as any).payment_telr_telrpayments_store_id,
    "authkey": (config.value as any).payment_telr_telrpayments_auth_key,
    "order": {
      "cartid": cart.value.id,
      "test": (config.value as any).payment_telr_telrpayments_sandbox,
      "amount": cartView.totals.value.total,
      "currency":  context.app.$vsf.$magento.config.state.getCurrency() || storeWiseCurrency(context.app.i18n.locale),
      "description": (config.value as any).payment_telr_telrpayments_transaction_desc,
      "trantype": (config.value as any).payment_telr_telrpayments_telr_txn_type,
    },
    "return": {
      "authorised": process.env.VSF_STORE_REDIRECT_URL+  (context.app.i18n.locale !== context.app.i18n.defaultLocale ?( '/' + context.app.i18n.locale + '/') : '/') + 'thank-you' + '?callback=telr-success',
      "declined":   process.env.VSF_STORE_REDIRECT_URL + (context.app.i18n.locale !== context.app.i18n.defaultLocale ?( '/' +  context.app.i18n.locale + '/') : '/') + 'failed-order' + '?callback=telr-failed',
      "cancelled":  process.env.VSF_STORE_REDIRECT_URL +  (context.app.i18n.locale !== context.app.i18n.defaultLocale ?(  '/' + context.app.i18n.locale + '/') : '/') + 'failed-order' + '?callback=telr-cancelled',
      "update": process.env.VSF_MAGENTO_BASE_URL + `/telr/standard/IvpCallback/?cart_id=${orderNumber}`
    },
    "customer": {
    "email": cart.value.email,
    "name": {
      "title": cart.value.billing_address.firstname + " " + cart.value.billing_address.lastname,
      "forenames": cart.value.billing_address.firstname,
      "surname": cart.value.billing_address.lastname
    },
    "address": {
      "line1": cart.value.billing_address?cart.value.billing_address.street[0] + ", " + cart.value.billing_address.city + " " + cart.value.billing_address.city + ", " + cart.value.billing_address.country.label : "",
      
      "city": cart.value.billing_address.city,
      "state": cart.value.billing_address.region.label,
      "country": cart.value.billing_address.country.label,
      "areacode": cart.value.billing_address.postcode,
    },
    "phone": cart.value.billing_address.telephone,
    }
  });


  if(telr_response.data?.ref){
    const query = `
        query TelrCreatePayment($coid: String!, $ref: String!) {
          TelrCreatePayment(coid: $coid, ref: $ref) {
            success
            error_message
          }
        }
      `;

    const {data} = await context.app.$vsf.$magento.api.customQuery({
      query,
      queryVariables: {
        coid:orderNumber,
        ref:telr_response.data?.ref
      }
    }) as any;

    if(data?.TelrCreatePayment?.success){
        if(telr_response.data?.url){
          window.location.href = telr_response.data.url
        } else {
          sendNotification({
            icon: 'error',
            id: Symbol(`subscription-error`),
            message: "Try again later something went wrong !!",
            persist: false,
            title: 'error',
            type: 'danger',
        });
          return 'response'
        }
    } else {
      sendNotification({
        icon: 'error',
        id: Symbol(`subscription-error`),
        message: "Try again later something went wrong !!",
        persist: false,
        title: 'error',
        type: 'danger',
      });
     return 'response'
    }

  } else {
    sendNotification({
      icon: 'error',
      id: Symbol(`subscription-error`),
      message: "Try again later something went wrong !!",
      persist: false,
      title: 'error',
      type: 'danger',
    });
    return 'response'
  }
  loading.value = false;
}



  return {
    make,
    error: readonly(error),
    loading: readonly(loading),
    tabbyCheckout,
    tamaraCheckout,
    telrCheckout
  };
}

export * from './useMakeOrder';
export default useMakeOrder;
