import axios from "axios";

export default async function ({ req, redirect }) {
  // Ensure this logic only runs on the server-side
//   if (!process.server) return;

// const read_ip_info = await axios.get('/read-ip-info');

// console.log("Reading read_ip_info ---->",read_ip_info.data);

//   // Get the user's IP
//   const ip =
//     req.headers['x-forwarded-for']?.split(',').shift() || req.connection?.remoteAddress;

//   // Resolve the user's location using geoip-lite
//   const geo = geoip.lookup(ip);

//   // Default to 'ksa_en' if country can't be detected
//   const defaultStore = 'ksa_en';
//   const countryToStoreMap = {
//     'SA': 'ksa_en', // Saudi Arabia
//     'AE': 'uae_en', // United Arab Emirates
//     'OM': 'omn_en', // Oman
//     'BH': 'bhr_en', // Bahrain
//     // Add other mappings as needed
//   };

//   // Determine the store based on the user's country
//   const userCountry = geo?.country || 'SA'; // Default to 'SA' (Saudi Arabia)
//   const store = countryToStoreMap[userCountry] || defaultStore;

//   // Get the current URL
//   const currentURL = req.url;

//   // Check if already redirected to the correct store
//   if (!currentURL.startsWith(`/${store}`)) {
//     // Redirect to the store-specific URL
//     redirect(`/${store}${currentURL}`);
//   }
}
