export default {
  "Phone": "Phone",
  "Follow Us": "Follow Us",
  "Enter your email": "Enter your email",
  "Subscribe to our newsletter for the latest updates and promotions": "Subscribe to our newsletter for the latest updates and promotions",
  "Stay Updated with Our Latest New": "Stay Updated with Our Latest News",
  "Privacy Policy": "Privacy Policy",
  "Terms of Service": "Terms of Service",
  "Return and Exchange Policy": "Return and Exchange Policy",
  "Shipping": "Shipping",
  "FAQs": "FAQs",
  "Contact Us": "Contact Us",
  "Help": "Help",
  "About Maz Holding Group": "About Maz Holding Group",
  "Our Partners": "Our Partners",
  "About us": "About us",
  "Massage & Recliner chairs": "Massage & Recliner chairs",
  "Beds": "Beds",
  "Pillows & bedding Accessories": "Pillows & Bedding Accessories",
  "Mattresses": "Mattresses",
  "Top Categories": "Top Categories",
  "Copyright © Bed Quarter 2024": "Copyright © Bed Quarter 2024",
  "For any inquiries or support, feel free to contact us via phone. Our team is available to assist you with any questions or concerns you may have.": "For any inquiries or support, feel free to contact us via phone. Our team is available to assist you with any questions or concerns you may have.",
  "Shop By Category": "Shop By Category",
  "Home Page": "Home Page",
  "Bahrain Home Page": "Bahrain Home Page",
  "Oman Home Page": "Oman Home Page",
  "UAE Home Page": "UAE Home Page",
  "FREE SHIPPING FOR THE SELECTED CITIES": "FREE SHIPPING FOR THE SELECTED CITIES",
  "CREATE AN ACCOUNT": "CREATE AN ACCOUNT",
  "United Arab Emirates": "United Arab Emirates",
  "Oman": "Oman",
  "Bahrain": "Bahrain",
  "Store Location": "Store Location",
  "English": "English",
  "Saudi Arabia": "Saudi Arabia",
  "STORE LOCATIONS": "STORE LOCATIONS",
  "Account": "Account",
  "Add new address": "Add new address",
  "Add to compare": "Add to compare",
  "Add to Wishlist": "Add to Wishlist",
  "Additional Information": "Additional Information",
  "All Orders": "All Orders",
  "Allow order notifications": "Allow order notifications",
  "Apply": "Apply",
  "Applied Coupon": "Applied Coupon",
  "Attention!": "Attention!",
  "Back to home": "Back to home",
  "Back to homepage": "Back to homepage",
  "Billing": "Billing",
  "Billing address": "Billing address",
  "Brand": "Brand",
  "TEMPUR PRO": "TEMPUR PRO",
  "Perfect setup with our delivery and installation": "Perfect setup with our delivery and installation",
  "Hassle-free returns for customer satisfaction": "Hassle-free returns for customer satisfaction",
  "Convenient online ordering and in-store pickup": "Convenient online ordering and in-store pickup",
  "NASA-patented comfort and durability": "NASA-patented comfort and durability",
  "Compare Products": "Compare Products",
  "You have no items to compare.": "You have no items to compare.",
  "You have no items in your wish list.": "You have no items in your wish list.",
  "You have no items in your shopping cart.": "You have no items in your shopping cart.",
  "OFFERS": "OFFERS",
  "Offer 2": "Offer 2",
  "Offer 1": "Offer 1",
  "View all": "View all",
  "Results": "Results",
  "Sorry, nothing has been found for": "Sorry, nothing has been found for",
  "Memory Foam Mattresses": "Memory Foam Mattresses",
  "Spring Mattresses": "Spring Mattresses",
  "Latex Mattresses": "Latex Mattresses",
  "Home Fragrances": "Home Fragrances",
  "Bed Linen": "Bed Linen",
  "Bathrobes and Towels": "Bathrobes and Towels",
  "Prayer Mat": "Prayer Mat",
  "Mattress & Pillow Protectors": "Mattress & Pillow Protectors",
  "Mattress Topper": "Mattress Topper",
  "Quilts and Duvets": "Quilts and Duvets",
  "Pillow": "Pillow",
  "Assorted Pillows": "Assorted Pillows",
  "Latex Pillows": "Latex Pillows",
  "Memory foam Pillows": "Memory foam Pillows",
  "L’editeur": "L’editeur",
  "LA BONNE MAISON": "LA BONNE MAISON",
  "Adjustable Beds": "Adjustable Beds",
  "Beds with Poly Base": "Beds with Poly Base",
  "Office chair": "Office chair",
  "Recliner Chairs": "Recliner Chairs",
  "Massage Chairs and Accessories": "Massage Chairs and Accessories",
  "Massage Chairs": "Massage Chairs",
  "Foot & Back Massagers": "Foot & Back Massagers",
  "View Deal": "View Deal",
  "BIG": "BIG",
  "For a quick response, reach out to us on WhatsApp. Our representatives are ready to help you with your needs.": "For a quick response, reach out to us on WhatsApp. Our representatives are ready to help you with your needs.",
  "Best Mattresses": "Best Mattresses",
  "World's": "World's",
  "Save": "Save",
  "on your favorite brands.": "on your favorite brands.",
  "Massage chairs": "Massage chairs",
  "from OSIM": "from OSIM",
  "Get the best international": "Get the best international",
  "Order online &": "Order online &",
  "Collect in store": "Collect in store",
  "Return &": "Return &",
  "Exchange policy": "Exchange policy",
  "Installation": "Installation",
  "Delivery &": "Delivery &",
  "WhatsApp": "WhatsApp",
  "to us": "to us",
  "Talk": "Talk",
  "Now": "Now",
  "WORLD'S BEST Brands": "WORLD'S BEST Brands",
  "Explore more": "Explore more",
  "Exclusive offers": "Exclusive offers",
  "NEW PRODUCTS": "NEW PRODUCTS",
  "LATEST DEALS": "LATEST DEALS",
  "Home": "Home",
  "Mattress": "Mattress",
  "Tempur The One Cooling Medium Feel 20": "Tempur The One Cooling Medium Feel 20",
  "In stock": "In stock",
  "Out stock": "Out of stock",
  "10 reviews": "10 reviews",
  "Quickrefresh cover": "Quickrefresh cover",
  "No Turn": "No Turn",
  "10 Year Guarantee": "10 Year Guarantee",
  "NASA Comfort & Durability": "NASA Comfort & Durability",
  "Quantity": "Quantity",
  "Size": "Size",
  "Customized Size": "Customized Size",
  "King single": "King single",
  "Vat Included Estimated Delivery Time: 2-4 Days": "Vat Included Estimated Delivery Time: 2-4 Days",
  "ADD TO CART": "ADD TO CART",
  "General Description": "General Description",
  "Customer Reviews": "Customer Reviews",
  "See what others think and share your experience with us": "See what others think and share your experience with us",
  "Reviewed by 29641 customers": "Reviewed by 29641 customers",
  "Write A Review": "Write A Review",
  "Mattress Description": "Mattress Description",
  "Responsive Support": "Responsive Support",
  "Advanced Cooling System": "Advanced Cooling System",
  "Hypoallergenic": "Hypoallergenic",
  "Long-Lasting Durability": "Long-Lasting Durability",
  "Washable at 40°C": "Washable at 40°C",
  "Mattress Care": "Mattress Care",
  "Mattress Features": "Mattress Features",
  "Great mattress!": "Great mattress!",
  "Lorem ipsum dolor sit amet adipiscing elit Lorem ipsum dolor sit amet adipiscing elit.": "Lorem ipsum dolor sit amet adipiscing elit Lorem ipsum dolor sit amet adipiscing elit.",
  "Read more reviews": "Read more reviews",
  "Cancel": "Cancel",
  "Cart": "Cart",
  "Categories": "Categories",
  "Change": "Change",
  "Change password your account": "Change your password",
  "Clear all": "Clear all",
  "Color": "Color",
  "Commercial information": "Commercial information",
  "Contact details updated": "Contact details updated",
  "Contact us": "Contact us",
  "Continue to billing": "Continue to billing",
  "Continue to payment": "Continue to payment",
  "Continue to shipping": "Continue to shipping",
  "Cookies Policy": "Cookies Policy",
  "Create an account": "Create an account",
  "Customer service": "Customer service",
  "Date": "Date",
  "Default Billing Address": "Default Billing Address",
  "Default Shipping Address": "Default Shipping Address",
  "Delete": "Delete",
  "Departments": "Departments",
  "Description": "Description",
  "Details and status orders": "Details and order status",
  "Discount": "Discount",
  "Done": "Done",
  "Download": "Download",
  "Download all": "Download all",
  "Edit": "Edit",
  "Email address": "Email address",
  "Empty": "Empty",
  "Enjoy your free account": "Enjoy your free account",
  "Enter promo code": "Enter promo code",
  "Feedback": "Feedback",
  "Feel free to edit": "Feel free to edit",
  "Filters": "Filters",
  "Find out more": "Find out more",
  "First Name": "First Name",
  "Forgot Password": "Forgot Password",
  "Forgotten password?": "Forgotten password?",
  "Go back": "Go back",
  "Go to cart": "Go to cart",
  "Guarantee": "Guarantee",
  "Help & FAQs": "Help & FAQs",
  "Hide": "Hide",
  "I agree to": "I agree to",
  "I confirm subscription": "I confirm subscription",
  "I want to create an account": "I want to create an account",
  "Info after order": "Info after order",
  "Instruction1": "Instruction",
  "Instruction2": "Did you just come for care instructions?",
  "Instruction3": "Yes, we thought so",
  "Item": "Item",
  "Items": "Items",
  "Kidswear": "Kidswear",
  "Last Name": "Last Name",
  "Log into your account": "Log into your account",
  "Login": "Login",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "Make an order": "Make an order",
  "Manage addresses": "Manage addresses",
  "Manage billing addresses": "Manage billing addresses",
  "Manage shipping addresses": "Manage shipping addresses",
  "Match it with": "Match it with",
  "Men fashion": "Men fashion",
  "Menu": "Menu",
  "My Cart": "My Cart",
  "No account": "No account",
  "Order ID": "Order ID",
  "Order summary": "Order summary",
  "Other products you might like": "Other products you might like",
  "Password": "Password",
  "Pay for order": "Pay for order",
  "Payment": "Payment",
  "Payment & Delivery": "Payment & Delivery",
  "Payment Method": "Payment Method",
  "Payment Methods": "Payment Methods",
  "Personal details": "Personal details",
  "Price": "Price",
  "Product": "Product",
  "Products": "Products",
  "Products found": "Products found",
  "Purchase terms": "Purchase terms",
  "Quality in the details": "Quality in the details",
  "Quantity": "Quantity",
  "Read all reviews": "Read all reviews",
  "Read and understand": "Read and understand",
  "Read reviews": "Read reviews",
  "Register": "Register",
  "Remove": "Remove",
  "Remove Address": "Remove Address",
  "Remove from Wishlist": "Remove from Wishlist",
  "Repeat Password": "Repeat Password",
  "Reset Password": "Reset Password",
  "Review my order": "Review my order",
  "Same as shipping address": "Same as shipping address",
  "Save changes": "Save changes",
  "Save for later": "Save for later",
  "Save Password": "Save Password",
  "Search": "Search",
  "Sections that interest you": "Sections that interest you",
  "See all results": "See all results",
  "See more": "See more",
  "Select payment method": "Select payment method",
  "Select shipping method": "Select shipping method",
  "Send my feedback": "Send my feedback",
  "Set up newsletter": "Set up newsletter",
  "Share your look": "Share your look",
  "Shipping address": "Shipping address",
  "Shipping details": "Shipping details",
  "Shipping method": "Shipping method",
  "Show": "Show",
  "Show more": "Show more",
  "Show on page": "Show on page",
  "Sign in": "Sign in",
  "Size guide": "Size guide",
  "Sign Up for Newsletter": "Sign Up for Newsletter",
  "Sort by": "Sort by",
  "Start shopping": "Start shopping",
  "Status": "Status",
  "Subscribe": "Subscribe",
  "Subtotal": "Subtotal",
  "Total": "Total",
  "Update password": "Update password",
  "Update personal data": "Update personal data",
  "User Account": "User Account",
  "View": "View",
  "Who we are": "Who we are",
  "Wishlist": "Wishlist",
  "You have submitted no reviews": "You have submitted no reviews",
  "You can unsubscribe at any time": "You can unsubscribe at any time",
  "SUMMER COLLECTION {year}": "SUMMER COLLECTION {year}",
  "Colorful summer dresses are already in store": "Colorful summer dresses are already in store",
  "Learn more": "Learn more",
  "Dresses": "Dresses",
  "Cocktail & Party": "Cocktail & Party",
  "Linen Dresses": "Linen Dresses",
  "T-Shirts": "T-Shirts",
  "The office life": "The office life",
  "Shop now": "Shop now",
  "Eco Sandals": "Eco Sandals",
  "Download our application to your mobile": "Download our application to your mobile",
  "Share Your Look": "Share Your Look",
  "Redefines Comfort": "Redefines Comfort",
  "SHOP NOW": "SHOP NOW",
  "Back": "Back",
  "New Products": "New Products",
  "Cloud Like Pillows": "Cloud Like Pillows",
  "Enjoy a peaceful and comfortable sleep with our pillows, designed to provide the perfect support for your head and neck. Made with high-quality materials to ensure you have quiet nights and sweet dreams": "Enjoy a peaceful and comfortable sleep with our pillows, designed to provide the perfect support for your head and neck. Made with high-quality materials to ensure you have quiet nights and sweet dreams",
  "Iconic Lazboy chair: Innovative design, unmatched comfort, whispers unwinding art": "Iconic Lazboy chair: Innovative design, unmatched comfort, whispers unwinding art",
  "My profile": "My profile",
  "Personal Details": "Personal Details",
  "Addresses details": "Addresses details",
  "Log out": "Log out",
  "latest Deals": "Latest Deals",
  "Order history": "Order history",
  "Order details": "Order details",
  "My wishlist": "My wishlist",
  "Password change": "Password change",
  "Your e-mail": "Your e-mail",
  "Your Account": "Your Account",
  "What can we improve": "What can we improve",
  "Payment date": "Payment date",
  "Thank you for your order!": "Thank you for your order!",
  "Primary contacts for any questions": "Primary contacts for any questions",
  "Your Purchase": "Your Purchase",
  "Back to Home page": "Back to Home page",
  "Page not found": "Page not found",
  "AllProductsFromCategory": "All {categoryName}",
  "Yes": "Yes",
  "No": "No",
  "Apply filters": "Apply filters",
  "From": "From",
  "To": "To",
  "Passwords don't match": "Passwords don't match",
  "Amount": "Amount",
  "Remove": "Remove",
  "Your cart is empty": "Your cart is empty",
  "Add to cart": "Add to cart",
  "Title": "Title",
  "Review": "Review",
  "Are you sure you would like to remove this item from the shopping cart?": "Are you sure you would like to remove this item from the shopping cart?",
  "Thank you": "Thank you"
};
