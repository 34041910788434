import {
  computed, readonly, ref, useContext, useRoute,
} from '@nuxtjs/composition-api';
import { addItemCommand } from '~/modules/checkout/composables/useCart/commands/addItemCommand';
import { applyCouponCommand } from '~/modules/checkout/composables/useCart/commands/applyCouponCommand';
import { loadCartCommand } from '~/modules/checkout/composables/useCart/commands/loadCartCommand';
import { loadTotalQtyCommand } from '~/modules/checkout/composables/useCart/commands/loadTotalQtyCommand';
import { removeCouponCommand } from '~/modules/checkout/composables/useCart/commands/removeCouponCommand';
import { removeItemCommand } from '~/modules/checkout/composables/useCart/commands/removeItemCommand';
import { updateItemQtyCommand } from '~/modules/checkout/composables/useCart/commands/updateItemQtyCommand';
import { Logger } from '~/helpers/logger';
import {
  Cart, CartItemInterface, ProductInterface, ProductStockStatus,
} from '~/modules/GraphQL/types';
import { useCartStore } from '~/modules/checkout/stores/cart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { Product } from '~/modules/catalog/product/types';
import { ComposableFunctionArgs, useUiNotification } from '~/composables';
import { UseCartErrors, UseCartInterface } from './useCart';
import { storeWiseCurrency } from '~/utils/HomePageIdentifiers';

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */

export function useCart<CART extends Cart, CART_ITEM extends CartItemInterface, PRODUCT extends ProductInterface>(): UseCartInterface<
CART,
CART_ITEM,
PRODUCT
> {
  const loading = ref<boolean>(false);
  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  });
  const { app } = useContext();
  const context = app.$vsf;
  const route = useRoute();
  const cartStore = useCartStore();
  const cart = computed(() => cartStore.cart as CART);
  const apiState = context.$magento.config.state;
  const { loading: wishlistLoading, afterAddingWishlistItemToCart } = useWishlist();
  const { send: sendNotification } = useUiNotification();
  const custom_option = ref([]);
  const remove_size = ref(0);
  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: CART): void => {
    Logger.debug('useCart.setCart', newCart);

    cartStore.$patch((state) => {
      state.cart = newCart;
    });
  };

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean => !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  const load = async ({ customQuery = {}, customHeaders = {}, realCart = false } = { customQuery: { cart: 'cart' }, customHeaders: {} }): Promise<void> => {
    Logger.debug('useCart.load');

    customHeaders = {
      'store':app.i18n.locale,
    }

    try {
      loading.value = true;
      const loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders, realCart });
      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCart/load', err);
    } finally {
      loading.value = false;
    }
  };

  const clear = async ({ customQuery, customHeaders } = { customQuery: { cart: 'cart' }, customHeaders: {} }): Promise<void> => {
    Logger.debug('useCart.clear');

    try {
      loading.value = true;
      context.$magento.config.state.removeCartId();
      const loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders });

      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
    } catch (err) {
      error.value.clear = err;
      Logger.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const loadTotalQty = async (params?: ComposableFunctionArgs<{}>): Promise<void> => {
    Logger.debug('useCart.loadTotalQty');

    try {
      loading.value = true;
      const totalQuantity = await loadTotalQtyCommand.execute(context, params);

      cartStore.$patch((state) => {
        state.cart.total_quantity = totalQuantity;
      });
    } catch (err) {
      error.value.loadTotalQty = err;
      Logger.error('useCart/loadTotalQty', err);
    } finally {
      loading.value = false;
    }
  };

  const addItem = async ({
    product, quantity, productConfiguration, customQuery, customHeaders,not_to_show
  }): Promise<void> => {
    Logger.debug('useCart.addItem', { product, quantity });
    customHeaders = {
      'store':app.i18n.locale
    }
    try {
      loading.value = true;

      if (!apiState.getCartId()) {
        await load({ realCart: true });
      }

      console.log("Product ----> while adding to cart !! customOptions ---->", custom_option);
      console.log("Data layer ---->",app.$gtm)
      app.$gtm.push({ ecommerce: null });  // Clear the previous ecommerce object.
      const product_object = {
        item_id: product?.sku,
        item_name: product?.name,
        affiliation: "Google Merchandise Store",
        // coupon: "SUMMER_FUN",
        // discount: 2.22,
        index: 0,
        // item_brand: "Google",
        // item_category: "Apparel",
        // item_category2: "Adult",
        // item_category3: "Shirts",
        // item_category4: "Crew",
        // item_category5: "Short sleeve",
        // item_list_id: "related_products",
        // item_list_name: "Related Products",
        // item_variant: "green",
        // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
        price: product?.price_range?.minimum_price?.final_price?.value,
        quantity: quantity
      }
  
      for (let index = 0; index < product?.categories.length; index++) {
        const element = product?.categories[index];
        product_object["item_category" + (index == 0 ? '' : index + 1)] = element.name
      }
      if(app.$gtm.push){
        app.$gtm.push({
          event: "add_to_cart",
          ecommerce: {
            currency: product?.price_range?.minimum_price?.final_price?.currency,
            value:product?.price_range?.minimum_price?.final_price?.value,
            items: [
            {
              product_object,
            }
            ]
          }
        });
      }
      
      const updatedCart = await addItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        quantity,
        productConfiguration,
        customQuery,
        customHeaders,
        custom_option
      });

      error.value.addItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
      if(not_to_show == 'true'){
        sendNotification({
          icon: 'success',
          id: Symbol(`product-added-to-cart-${product.uid}`),
          message: "Product size updated !!",
          persist: false,
          title: 'Add to cart',
          type: 'success',
        })
      } else {
        sendNotification({
          icon: 'success',
          id: Symbol(`product-added-to-cart-${product.uid}`),
          message: "Product added to cart !!",
          persist: false,
          title: 'Add to cart',
          type: 'success',
        })
      }
      
      remove_size.value++
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useCart/addItem', err);
      sendNotification({
        icon: 'error',
        id: Symbol(`product-added-to-cart-${product.uid}`),
        message: err.message,
        persist: false,
        title: 'Registration error',
        type: 'danger',
      })
    } finally {
      if (!wishlistLoading.value && route.value.query?.wishlist) {
        afterAddingWishlistItemToCart({
          product,
          cartError: error.value.addItem,
        });
      }
      loading.value = false;
    }
  };

  const removeItem = async ({ product, customQuery, customHeaders }) => {
    Logger.debug('useCart.removeItem', { product });
    customHeaders = {
      'store':app.i18n.locale
    }

    console.log("Product ----> while adding to cart !! ---->", product);
    console.log("Data layer ---->",app.$gtm)
    app.$gtm.push({ ecommerce: null });  // Clear the previous ecommerce object.
    const product_object = {
      item_id: product?.product?.uid,
      item_name: product?.product?.name,
      affiliation: "Google Merchandise Store",
      // coupon: "SUMMER_FUN",
      // discount: 2.22,
      index: 0,
      // item_brand: "Google",
      // item_category: "Apparel",
      // item_category2: "Adult",
      // item_category3: "Shirts",
      // item_category4: "Crew",
      // item_category5: "Short sleeve",
      // item_list_id: "related_products",
      // item_list_name: "Related Products",
      // item_variant: "green",
      // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
      price: product?.prices?.row_total_including_tax?.value,
      quantity: product?.quantity
    }

    for (let index = 0; index < product?.product?.categories?.length; index++) {
      const element = product?.product?.categories[index];
      product_object["item_category" + (index == 0 ? '' : index + 1)] = element.name
    }
    if(app.$gtm.push){
      app.$gtm.push({
        event: "remove_from_cart",
        ecommerce: {
          currency: app.$vsf.$magento.config.state.getCurrency() ||  storeWiseCurrency(app.i18n.locale),
          value:product?.prices?.row_total_including_tax?.value,
          items: [
          {
            product_object,
          }
          ]
        }
      });
    }


    try {
      loading.value = true;
      const updatedCart = await removeItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        customQuery,
        customHeaders,
      });

      error.value.removeItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useCart/removeItem', err);
    } finally {
      loading.value = false;
    }
  };

  const updateItemQty = async ({ product, quantity, customQuery = { updateCartItems: 'updateCartItems' } }): Promise<void> => {
    Logger.debug('useCart.updateItemQty', {
      product,
      quantity,
    });
    console.log("Update Quantity")
    if (quantity && quantity > 0) {
      try {
        loading.value = true;
        const updatedCart = await updateItemQtyCommand.execute(context, {
          currentCart: cart.value,
          product,
          quantity,
          customQuery,
          customHeaders:{
            'store' : app.i18n.locale
          }
        });

        error.value.updateItemQty = null;
        cartStore.$patch((state) => {
          state.cart = updatedCart;
        });
        sendNotification({
          icon: 'success',
          id: Symbol(`product-added-to-cart-${product.uid}`),
          message: "Product Quantity updated !!",
          persist: false,
          title: 'Product Quantity updated',
          type: 'success',
        });
      } catch (err) {
        error.value.updateItemQty = err;
        Logger.error('useCart/updateItemQty', err);
        sendNotification({
          icon: 'error',
          id: Symbol(`product-added-to-cart-${product.uid}`),
          message: "The requested qty exceeds the maximum qty allowed in shopping cart",
          persist: false,
          title: 'The requested qty exceeds the maximum qty allowed in shopping cart',
          type: 'danger',
        });
      } finally {
        loading.value = false;
      }
    }
  };

  const handleCoupon = async (couponCode = null, customQuery = null): Promise<void> => {
    const variables = {
      currentCart: cart.value,
      customQuery,
      couponCode,
      customHeaders: {
        'store':app.i18n.locale
      }
    };

    const { updatedCart, errors } = couponCode
      ? await applyCouponCommand.execute(context, variables)
      : await removeCouponCommand.execute(context, variables);

    if (errors) {
      throw errors[0];
    }

    if (updatedCart) {
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    }
  };

  const applyCoupon = async ({ couponCode, customQuery }): Promise<void> => {
    Logger.debug('useCart.applyCoupon');

    try {
      loading.value = true;
      await handleCoupon(couponCode, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.applyCoupon = err;
      Logger.error('useCart/applyCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const removeCoupon = async ({ customQuery }): Promise<void> => {
    Logger.debug('useCart.removeCoupon');

    try {
      loading.value = true;
      await handleCoupon(null, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.removeCoupon = err;
      Logger.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const canAddToCart = (product: Product, qty = 1) => {
   
    // eslint-disable-next-line no-underscore-dangle
    if (product?.__typename === 'ConfigurableProduct') {
      return !!product?.configurable_product_options_selection?.variant
        ?.uid;
    }
    const inStock = product?.stock_status === ProductStockStatus.InStock;
    const stockLeft = product?.only_x_left_in_stock === null
      ? true
      : qty <= product?.only_x_left_in_stock;

    return inStock && stockLeft;
  };

  return {
    setCart,
    cart,
    loadTotalQty,
    isInCart,
    addItem,
    load,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    loading: readonly(loading),
    error: readonly(error),
    custom_option,
    remove_size
  };
}

export default useCart;
export * from './useCart';
