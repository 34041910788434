//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import { useContext, useRouter, watch,computed,ref, onMounted, onBeforeUnmount } from '@nuxtjs/composition-api';
  import { useWishlist,useUiNotification, useConfig } from '~/composables';
  import useCompare from '~/composables/useCompare';
  
  export default {
    name: 'MainPopup',
    props: {
      show:{
        type:Boolean,
        default:false
      }
    },
    setup(props, { emit }) {
        const popup = ref(props.show);
        const email = ref('');
        const error = ref("");
        const subscribeLoader = ref(false);
        const {config} = useConfig();
        const { send: sendNotification } = useUiNotification();
        const {app} = useContext();
        const popup_check = computed(() => {
      return (config.value)?.bqconfigsection_newsletter_config_enable;
    })
        const validateEmail = (email) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };
    watch(email, () => {
      if (email.value.trim() && error.value == 'Email is required') {
        error.value = "";
      }
    })

    const MutationQuery = `
  mutation subscribeEmailToNewsletter($email: String!) {
    subscribeEmailToNewsletter(
      email: $email
    ) {
      status
    }
  }
`;

        const subscribe = async (e) => {
            e.preventDefault();
            console.log("Prevent Default !!")

            if (!email.value.trim()) {
        error.value = "Email is required";
        return;
      }
      if (!validateEmail(email.value.trim())) {
        error.value = "Please enter a valid email address";
        return;
      }
      subscribeLoader.value = true
      error.value = "";
      

      const {data,errors} = await app.$vsf.$magento.api.customQuery({
        query: MutationQuery,
        queryVariables: {
          email: email.value
        },
        customHeaders:{
          'store':app.i18n.locale
        }
      });

      if(errors){
        subscribeLoader.value = false
        
        sendNotification({
          icon: 'error',
          id: Symbol(`subscription-error`),
          message: errors[0].message,
          persist: false,
          title: 'error',
          type: 'danger',
        })
      } else {
        subscribeLoader.value = false
        sendNotification({
          icon: 'success',
          id: Symbol(`subscription-message`),
          message: 'Successfully subscribed !!',
          persist: false,
          title: 'success',
          type: 'success',
        });
        email.value = "";
        popup.value = false
      }
        }

        const disableScroll = () => {
      document.body.style.overflow = 'hidden';
    };

    const enableScroll = () => {
      document.body.style.overflow = '';
    };
    const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

const setCookie = (name, value, days) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};

    onMounted(() => {
    //   disableScroll();

    
   
        const popup_value = getCookie('popup');
        if(!popup_value){
        popup.value = true;
        setCookie('popup', 'true', 7);
        } else {
        popup.value = false;
        }
    

   

    });
    
    onBeforeUnmount(() => {
      enableScroll();
    })
    
      return {
        email,
        subscribe,
        subscribeLoader,
        error,
        popup,
        config,
        popup_check,
        imageUrl: computed(()=> {
            return process.env.VSF_MAGENTO_BASE_URL + '/media/magiccart/img/' + config.value?.bqconfigsection_newsletter_config_image
        })
      };
    },
  };
  