








































































































import {
  defineComponent,
  computed,
  ref,
  useContext,
  onMounted,
} from "@nuxtjs/composition-api";
import { SfButton, SfTopBar } from "@storefront-ui/vue";
import { useTopBar } from "./useTopBar";
import {
  countryCodeMap,
  replacePartInURL,
  determineStore,
  availableStoreCountries,
  languageSwitcher,
  determineLanguage
} from "../../utils/HomePageIdentifiers";
import { useConfig } from "~/composables";

export default defineComponent({
  components: {
    CurrencySelector: () => import("../CurrencySelector.vue"),
    StoreSwitcher: () => import("../StoreSwitcher.vue"),
    SfTopBar,
    SfButton,
  },
  setup() {
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();
    const { app } = useContext();
    const selectedCountry = ref({
      name: "Saudi Arabia",
      flag: require("../../assets/images/saudi-arab.svg"),
    });
    const selectedLanguage = ref({ name: 'عربي',code:'ar' });
    const dropdownOpen = ref(false);
    const {config} = useConfig();
    const logo_src = computed(() => {
        return process.env.VSF_MAGENTO_BASE_URL + '/media/logo/' + (config.value as any)?.bqconfigsection_notice_config_image
      })
      const logo__title = computed(() => {
        return  (config.value as any)?.bqconfigsection_notice_config_title
      })
    const filteredCountries = computed(() => {
      return availableStoreCountries.filter(
        (country) => country.name !== selectedCountry.value.name
      );
    });
    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value;
    };
    // const selectCountry = (country) => {
    //   selectedCountry.value = country;
    //   dropdownOpen.value = false;
    //   const currentURL = window.location.href;

    //   const languageCode =
    //     selectedLanguage.value.name === 'إنجليزي' ? "Arabic" : "English";
    //   const routeCode = countryCodeMap[country.name][languageCode];

    //   if (routeCode) {
    //     const route = replacePartInURL(currentURL, app.i18n.locale, routeCode);
    //     window.location.href = route;
    //   }
    // };

//     const selectCountry = (country) => {
//   console.log("Check selected country ---->", country);

//   // Get the current URL and extract the base domain
//   const currentURL = window.location.href;
//   const baseURL = currentURL.split('/').slice(0, 3).join('/'); // Extract base domain (e.g., http://localhost:3000)

//   // Define the current language based on selectedLanguage.value
//   const languageCode =
//     selectedLanguage.value.name === 'إنجليزي' ? 'ar' : 'en';

//   // Function to sanitize the path
//   const sanitizePath = (path) => {
//     return path.replace(/^([^/]+_)?[^/]+/, '').replace(/^\/+/, ''); // Remove dynamic flags and leading slashes
//   };

//   // Extract and sanitize the current path
//   const currentPath = currentURL.split('/').slice(3).join('/'); // Extract path after domain
//   const sanitizedPath = sanitizePath(currentPath);
//   console.log("Sanitized Path ---->", sanitizedPath);

//   // Check if the selected country is "Saudi Arabia"
//   if (country.name === 'Saudi Arabia') {
//     // Redirect to the base URL with the current path without any dynamic prefix
//     const updatedURL = sanitizedPath ? `${baseURL}/${sanitizedPath}` : baseURL;
//     console.log("Updated URL for Saudi Arabia:", updatedURL);
//     // window.location.href = updatedURL; // Redirect to the updated URL
//     return;
//   }

//   // For other countries, add the dynamic prefix based on the selected country and language
//   const countryCodeMap = {
//     'United Arab Emirates': 'uae',
//     'Oman': 'omn',
//     'Bahrain': 'bhr',
//     // Add other countries as needed
//   };

//   const countryPrefix = countryCodeMap[country.name];
//   if (countryPrefix) {
//     // Construct the new URL with the selected country and language
//     const updatedURL = `${baseURL}/${countryPrefix}_${languageCode}${sanitizedPath ? `/${sanitizedPath}` : ''}`;
//     console.log("Updated URL for other countries:", updatedURL);
//     // window.location.href = updatedURL; // Redirect to the updated URL
//   }
// };


const selectCountry = (country) => {
  console.log("Check selected country ---->", country);

  // Get the current URL and extract the base domain
  const currentURL = window.location.href;
  const baseURL = currentURL.split('/').slice(0, 3).join('/'); // Extract base domain (e.g., http://localhost:3000)

  // Define the current language based on selectedLanguage.value
  const languageCode =
    selectedLanguage.value.name === 'إنجليزي' ? 'ar' : 'en';

  // Function to sanitize the path
  const sanitizePath = (path) => {

    if (app.i18n.locale === 'ksa_en') {
      // If on default store, remove only dynamic prefixes, keep the rest of the path
      return path.replace(/^([^/]+_)/, '').replace(/^\/+/, ''); // Remove leading dynamic prefix and clean up leading slashes
    }

    if(country.name == 'Saudi Arabia'){
      return  path.replace(/^([^/]+_)?[^/]+/, '').replace(/^\/+/, ''); // Remove leading slashes only
    }
    // Remove dynamic prefixes and leading slashes
    return path.replace(/^([^/]+_)?[^/]+/, '').replace(/^\/+/, '');
  };

  // Extract and sanitize the current path
  const currentPath = currentURL.split('/').slice(3).join('/'); // Extract path after domain
  const sanitizedPath = sanitizePath(currentPath);
  console.log("Sanitized Path ---->", sanitizedPath);

  // Check if the selected country is "Saudi Arabia"
  if (country.name === 'Saudi Arabia') {
    // Redirect to the base URL with the sanitized path
    const updatedURL = sanitizedPath ? `${baseURL}${languageCode == 'ar' ? '/ksa_ar' : ''}/${sanitizedPath}` : baseURL + (languageCode == 'ar' ? '/ksa_ar' : '');
    console.log("Updated URL for Saudi Arabia:", updatedURL);
    window.location.href = updatedURL; // Uncomment for actual redirection
    return;
  }

  // For other countries, add the dynamic prefix based on the selected country and language
  const countryCodeMap = {
    'United Arab Emirates': 'uae',
    'Oman': 'omn',
    'Bahrain': 'bhr',
    // Add other countries as needed
  };

  const countryPrefix = countryCodeMap[country.name];
  if (countryPrefix) {
    // Construct the new URL with the selected country and language
    const updatedURL = sanitizedPath
      ? `${baseURL}/${countryPrefix}_${languageCode}/${sanitizedPath}`
      : `${baseURL}/${countryPrefix}_${languageCode}`;
    console.log("Updated URL for other countries:", updatedURL);
    window.location.href = updatedURL; // Uncomment for actual redirection
  }
};



    // const selectLanguage = (lng) => {
    //   selectedLanguage.value = {
    //     name:lng,
    //     code : lng === 'إنجليزي' ? "en" : "ar",
    //   };
    //   const route = languageSwitcher(app.i18n.locale);
    //   const currentURL = window.location.href;
    //   const newURL = currentURL.replace(/([a-z]{3}_[a-z]{2})/, route);
    //   window.history.replaceState(null, null, newURL);
    //   window.location.reload();
    // }

    const selectLanguage = async (lng) => {
  // Set the selected language
  selectedLanguage.value = {
    name: lng,
    code: lng === 'إنجليزي' ? 'en' : 'ar',
  };
  
  // Determine the new route using the language switcher
  const route = languageSwitcher(app.i18n.locale);

  // Get the current URL
  const currentURL = window.location.href;

  let newURL;
  if (app.i18n.locale === 'ksa_en' && route === 'ksa_ar') {
    // If switching from `ksa_en` to `ksa_ar`, add the prefix `ksa_ar`
    const baseURL = currentURL.split('/').slice(0, 3).join('/'); // Extract the domain
    const path = currentURL.split('/').slice(3).join('/'); // Extract the path after domain
    newURL = `${baseURL}/ksa_ar${path ? `/${path}` : ''}`; // Add '/ksa_ar/' prefix to the path
    console.log("While switching to ksa_ar", newURL);
      // Update the browser's URL and reload
    window.history.replaceState(null, null, newURL);
    window.location.reload();
  } else if (route === 'ksa_en') {
    // If switching to `ksa_en`, avoid creating a new URL if already in default state
    if (currentURL.includes('/ksa_ar') || currentURL.match(/([a-z]{3}_[a-z]{2})/)) {
      // If the current URL includes `/ksa_ar` or another prefix, clean it
      const baseURL = currentURL.split('/').slice(0, 3).join('/'); // Extract the domain
      const path = currentURL.split('/').slice(3).join('/'); // Extract the path after domain
      newURL = `${baseURL}/${path.replace(/^([a-z]{3}_[a-z]{2})\//, '')}`; // Remove any existing prefix
      console.log("While switching to ksa_en 111", newURL.includes('/ksa_ar') ? newURL.replace('/ksa_ar','') : newURL.replace('/ksa_ar','/'));
      // Update the browser's URL and reload
      
      // Set new store via server (server handles cookie removal + update)
      await fetch(`/api/set-store?to=ksa_en`, { credentials: 'include' });

      window.history.replaceState(null, null, newURL.replace('/ksa_ar',''));
      setTimeout(() => {
      window.location.reload();
        
      }, 1000);
    } else {
      // Already on default `ksa_en`, no URL change needed
      console.log("Already on default ksa_en, no URL change needed.");
      return;
    }
  } else {
    // Replace the existing language prefix with the new one for other routes
    newURL = currentURL.replace(/([a-z]{3}_[a-z]{2})/, route);
    console.log("While switching to all others store ---->", newURL);
    // Update the browser's URL and reload
  window.history.replaceState(null, null, newURL);
  window.location.reload();
  return
  }

  
};





    onMounted(() => {
      selectedLanguage.value = determineLanguage(app.i18n.locale)
      selectedCountry.value = determineStore(app.i18n.locale);
    });
    return {
      hasCurrencyToSelect,
      hasStoresToSelect,
      filteredCountries,
      toggleDropdown,
      selectedCountry,
      selectCountry,
      dropdownOpen,
      selectLanguage,
      selectedLanguage,
      config:(config.value as any),
      logo_src,
      logo__title
    };
  },
});
