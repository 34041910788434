import { ComposableFunctionArgs } from '~/composables/types';
import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import { useContext } from '@nuxtjs/composition-api';

export type CustomerProductReviewParams = {
  pageSize: number;
  currentPage: number;
};

export const loadCustomerReviewsCommand = {
  execute: async (context: VsfContext, params?: ComposableFunctionArgs<CustomerProductReviewParams>) => {
    Logger.debug('[Magento] load customer review based on:', { params });
  
    // const  customHeaders = {
    //   'store':app.i18n.locale,
    //   ...params?.customHeaders
    // }
    const { data } = await context.$magento.api.customerProductReview(params, params?.customQuery ?? null, params?.customHeaders ?? null);

    Logger.debug('[Result]:', { data });

    return data?.customer ?? {};
  },
};
