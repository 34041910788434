//
//
//
//
//
//
//
//

export default {
  name: 'Loader',
  props: {
    isLoading: {
      type: Boolean,
      required: true
    }
  }
};
