import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import { CreateProductReviewInput } from '~/modules/GraphQL/types';
import { ComposableFunctionArgs } from '~/composables/types';
import { useContext } from '@nuxtjs/composition-api';

export const addReviewCommand = {
  execute: async (context: VsfContext, params: ComposableFunctionArgs<CreateProductReviewInput>) => {
    Logger.debug('[Magento] add review params input:', JSON.stringify(params, null, 2));
    // const  customHeaders = {
    //   'store':app.i18n.locale,
    //   ...params?.customHeaders
    // }
    const {
      customQuery,
      ...input
    } = params;

    // const response = await context.$magento.api.createProductReview(input, params?.customQuery ?? null, params?.customHeaders);
    const response = await context.$magento.api.customMutation({
      mutation: `
        mutation createProductReview($input: CreateProductReviewInput!) {
          createProductReview(input: $input) {
            review {
              average_rating
              ratings_breakdown {
                name
                value
              }
              nickname
              summary
              text
              created_at
            }
          }
        }
      `,
      mutationVariables: {
        input: {
          nickname: input.nickname,
          sku: input.sku,
          summary: input.summary,
          text: input.text,
          ratings: input.ratings,
        },
      },
      customHeaders: params.customHeaders, // Pass custom headers separately
    });
    
    Logger.debug('[Result]:', { response });

    return response ?? response;
  },
};
