import { render, staticRenderFns } from "./Notification.vue?vue&type=template&id=11b3afa8&scoped=true&"
import script from "./Notification.vue?vue&type=script&lang=ts&"
export * from "./Notification.vue?vue&type=script&lang=ts&"
import style0 from "./Notification.vue?vue&type=style&index=0&id=11b3afa8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11b3afa8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgImage: require('/var/www/domains/bedquarter.com/public_html/components/General/SvgImage.vue').default})
