//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref } from 'vue'

export default {
    name: 'SignInModal',
    setup() {
        const isVisible = ref(false)
        const title = ref('')

        function showModal() {
            isVisible.value = true
        }

        function closeModal() {
            isVisible.value = false
        }

        defineExpose({
            showModal,
            closeModal
        })
    }

}
