





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { SfOverlay, SfHeader, SfButton, SfBadge } from "@storefront-ui/vue";
import { useCategory } from "~/modules/catalog/category/composables/useCategory";
import ConfirmationDialog from "~/components/ConfirmationDialog.vue";
import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
  watch,
  onUnmounted,
  reactive,
} from "@nuxtjs/composition-api";
import HeaderNavigation from "~/components/Header/Navigation/HeaderNavigation.vue";
import {
  useCartView,
  useProduct,
  useUiHelpers,
  useUiState,
  useUiNotification,
  useConfig,
} from "~/composables";
import { useCart } from "~/modules/checkout/composables/useCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useUser } from "~/modules/customer/composables/useUser";
import { useWishlistStore } from "~/modules/wishlist/store/wishlistStore";
import type {
  CategoryTree,
  ProductInterface,
  WishlistItemInterface,
} from "~/modules/GraphQL/types";
import HeaderLogo from "~/components/HeaderLogo.vue";
import SvgImage from "~/components/General/SvgImage.vue";
import { useTopBar } from "./TopBar/useTopBar";
import suggestedSearches from "./TopBar/suggestedSearch.gql";
import CategoryDropdownComponent from "~/components/dropdowncomponent/CategoryDropdownComponent.vue";
import SignInModal from "./Modal/SignInModal.vue";
import useCompare from "~/composables/useCompare";
import Loader from "./Loader.vue";

export default defineComponent({
  components: {
    HeaderNavigation,
    Loader,
    CategoryDropdownComponent,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    ConfirmationDialog,
    SfButton,
    SfBadge,
    SignInModal,
    CurrencySelector: () => import("~/components/CurrencySelector.vue"),
    StoreSwitcher: () => import("~/components/StoreSwitcher.vue"),
    SearchBar: () => import("~/components/Header/SearchBar/SearchBar.vue"),
    SearchResults: () =>
      import(
        /* webpackPrefetch: true */ "~/components/Header/SearchBar/SearchResults.vue"
      ),
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { getProductList, getProductPath } = useProduct();
    const {
      loadCompareList,
      compare_list,
      totalCompareCount,
      removeProductFromCompareList,
      clearCompare,
    } = useCompare();
    const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal } =
      useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const {
      loadTotalQty: loadCartTotalQty,
      cart,
      addItem,
      error: cartError,
      loading: isCartLoading,
      removeItem: removeProduct,
    } = useCart();
    const {
      loadItemsCount: loadWishlistItemsCount,
      loading: wishloader,
      removeItem,
      load: loadWishlist,
    } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } =
      useCategory();
    const logo_src = computed(() => {
      return (
        process.env.VSF_MAGENTO_BASE_URL +
        "/media/logo/" +
        (config.value as any)?.header_logo_src
      );
    });
    const currentCategory = ref<CategoryTree | null>(null);
    const shoppingCart = ref(false);
    const wishCart = ref(false);
    const compareCart = ref(false);
    const searchTerm = ref("");
    const searchTotalCount = ref(0);
    const searchInput = ref(false);
    const searchItemsDropdown = ref(false);
    const searchResults = ref<any>(null);
    const search_loader = ref(false);
    const suggested_searches = ref<any>(null);
    const offerDropdown = ref(false);
    const cartView = useCartView();
    const shoppingCartRef = ref(null);
    const isModalVisible = ref(false);
    const wishCartRef = ref<HTMLElement | null>(null);
    const compareCartRef = ref<HTMLElement | null>(null);
    const editCart = ref(null);
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();
    const categoryDropdown = ref(false);
    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const updateSliderIndex = ref(0);
    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(
      () => wishlistStore.wishlist?.items_count ?? 0
    );
    const updateLoader = ref(false);
    const isHeaderFixed = ref(false);
    const selectedSizeOption = ref({});
    const chooseOptionError = ref("");
    const scrollStates = ref({});

    const showDialog = ref(false);
    const { config } = useConfig() as any;
    const previous_product = ref(null);
    const { getProductDetails, loading: productLoading } = useProduct();
    const product_configuration = ref<any>(null);
    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const scrollableDiv = ref([]);
    const edit_product_quantity = ref(1);
    const { send: sendNotification } = useUiNotification();
    const delete_product = ref(null);
    const deleteProduct = (product) => {
      delete_product.value = product;
    };
    const handleConfirm = async () => {
      try {
        await cartView.removeItemAndSendNotification(delete_product.value);
      } catch (error) {
      } finally {
        delete_product.value = null;
      }
    };
    const handleCancel = () => {
      delete_product.value = null;
      showDialog.value = false;
    };

    const scrollLeft = (index) => {
      const container = document.querySelector(
        `.scrollable-container-${index}`
      );
      if (container) {
        container.scrollTo({
          left: container.scrollLeft - 115, // Adjust based on item width
          behavior: "smooth",
        });
        checkScrollState(index); // Update the scroll state for this index
      }
    };

    const getScrollState = (index) => {
      return computed(
        () =>
          scrollStates[index] || {
            atStart: true,
            atEnd: false,
            isScrollable: false,
          }
      );
    };

    const scrollRight = (index) => {
      const container = document.querySelector(
        `.scrollable-container-${index}`
      );
      if (container) {
        container.scrollTo({
          left: container.scrollLeft + 115, // Adjust based on item width
          behavior: "smooth",
        });
        checkScrollState(index); // Update the scroll state for this index
      }
    };
    const handleScroll = () => {
      isHeaderFixed.value = window.scrollY > 100;
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    // Check if the container is scrollable and update the state
    const checkScrollState = (index) => {
      const container = document.querySelector(
        `.scrollable-container-${index}`
      );
      if (container) {
        console.log("container --->", container);
        console.log(`scrollLeft: ${container.scrollLeft}`);
        console.log(`scrollWidth: ${container.scrollWidth}`);
        console.log(`clientWidth: ${container.clientWidth}`);
        console.log(
          `Math.ceil(scrollLeft + clientWidth): ${Math.ceil(
            container.scrollLeft + container.clientWidth
          )}`
        );
        const atStart = container.scrollLeft == 0;
        const atEnd =
          Math.ceil(container.scrollLeft + container.clientWidth) >=
          container.scrollWidth;
        const isScrollable = container.scrollWidth > container.clientWidth;

        // Update state for the specific index
        scrollStates.value = {
          ...scrollStates.value,
          [index]: {
            atStart,
            atEnd,
            isScrollable,
          },
        };

        console.log("scrollStates ---->", scrollStates.value[index], index);
      }
    };

    const wishlistProducts = computed<WishlistItemInterface[]>(
      () => wishlistStore.wishlist.items_v2?.items ?? []
    );

    watch(wishlistProducts, () => {});

    watch(searchTerm, async (newText) => {
      if (searchTerm.value.trim().length > 2) {
        searchItemsDropdown.value = true;
        search_loader.value = true;
        const data = await getProductList({ search: newText });
        searchResults.value = data.items;
        searchTotalCount.value = data.total_count ?? 0;
        if (data.items) {
          search_loader.value = false;
        }
      } else {
        searchItemsDropdown.value = false;
        searchResults.value = null;
      }
    });

    const accountIcon = computed(() =>
      isAuthenticated.value ? "profile_fill" : "profile"
    );
    const categoryTree = ref<CategoryTree[]>([]);

    const handleAccountClick = async () => {
      try {
        if (isAuthenticated.value) {
          // If the user is authenticated, navigate to the profile page
          await router.push(app.localeRoute({ name: "customer-my-profile" }));
        } else {
          await router.push(app.localeRoute({ name: "signin" }));
        }
      } catch (error) {
        console.error("Error during navigation:", error);
      }
    };

    const setCurrentCategory = (category: CategoryTree | null) => {
      currentCategory.value = category;
    };

    onMounted(async () => {
      await loadWishlist({ searchParams: { pageSize: 100 } });
      await loadCompareList();
      await categoriesListLoad({ pageSize: 20 });

      await app.$vsf.$magento.api
        .customQuery({
          query: suggestedSearches,
          customHeaders: {
            store: app.i18n.locale,
          },
        })
        .then((res) => {
          suggested_searches.value = res.data;
        });

      categoryTree.value = categoryList.value
        .filter((category) => category.include_in_menu)
        .slice(0, 4);
    });

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
    });

    // Track mouse movement to detect if it's moving up or sideways
    const trackMouseEnter = (item) => {
      categoryDropdown.value = true;
      setCurrentCategory(item);
      shoppingCart.value = false;
      wishCart.value = false;
      compareCart.value = false;
      offerDropdown.value = false;
      searchInput.value = false;
    };

    const goToCart = async () => {
      shoppingCart.value = false;
      await router.push(app.localeRoute({ name: "cart" }));
    };

    const proceedToCheckout = async () => {
      shoppingCart.value = false;
      cartView.goToCheckout();
    };

    const updateShoppingCart = (e) => {
      e.stopPropagation();
      if (shoppingCart.value) {
        shoppingCart.value = false;
      } else {
        shoppingCart.value = true;
        wishCart.value = false;
        compareCart.value = false;
        offerDropdown.value = false;
      }
    };
    const updateWishCart = (e) => {
      e.stopPropagation();
      if (wishCart.value) {
        wishCart.value = false;
      } else {
        wishCart.value = true;
        shoppingCart.value = false;
        compareCart.value = false;
        offerDropdown.value = false;
      }
    };
    const updateCompareCart = (e) => {
      e.stopPropagation();

      if (compareCart.value) {
        compareCart.value = false;
      } else {
        compareCart.value = true;
        wishCart.value = false;
        shoppingCart.value = false;
        offerDropdown.value = false;
      }
    };

    const updateSearch = () => {
      searchInput.value = true;
      wishCart.value = false;
      shoppingCart.value = false;
      compareCart.value = false;
      offerDropdown.value = false;
    };

    const redirectToWishList = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      router.push(app.localeRoute({ name: "customer-my-wishlist" }));
      wishCart.value = false;
    };
    const searchProductHandler = (product: any) => {
      const url = getProductPath(product);
      router.push(app.localeRoute(url));
      searchItemsDropdown.value = false;
      searchInput.value = false;
      wishCart.value = false;
      shoppingCart.value = false;
      compareCart.value = false;
      searchTerm.value = "";
    };

    const searchFromSuggestion = (item) => {
      searchTerm.value = item;
    };

    watch(totalCompareCount, () => {});

    const redirectToCompareList = () => {
      router.push(app.localeRoute({ name: "compare" }));
      window.scrollTo({ top: 0, behavior: "smooth" });
      compareCart.value = false;
    };

    const toggleOfferDropdown = () => {
      if (offerDropdown.value) {
        offerDropdown.value = false;
      } else {
        offerDropdown.value = true;
        shoppingCart.value = false;
        wishCart.value = false;
        compareCart.value = false;
      }
    };

    // Function to close cart when clicked outside
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      if (shoppingCartRef.value && !shoppingCartRef.value.contains(target)) {
        shoppingCart.value = false;
      }
      if (wishCartRef.value && !wishCartRef.value.contains(target)) {
        wishCart.value = false;
      }
      if (compareCartRef.value && !compareCartRef.value.contains(target)) {
        compareCart.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    const updateEdit = async (product, qty) => {
      console.log("updateEdit", product);
      editCart.value = product;
      previous_product.value = product;
      updateLoader.value = true;
      edit_product_quantity.value = qty;
      const configuralOptions = (await app.$vsf.$magento.api.customQuery({
        query: `
        
        query getConfigurableOptions($sku: String!) {
        products(filter: { sku: { eq: $sku } }) {
          items {
            sku
            __typename
            media_gallery {
              url
              position
              disabled
              label
            }


            ... on CustomizableProductInterface {
              options {
                title
                required
                uid
                sort_order
                option_id
                ... on CustomizableDropDownOption {
                  value {
                    uid
                    sku
                    price
                    price_type
                    title
                  }
                }
              }
            }

            ... on ConfigurableProduct {
              configurable_options {
                id
                attribute_id_v2
                label
                position
                attribute_uid
                use_default
                attribute_code
                values {
                  value_index
                  label
                  uid
                }
                product_id
              }
            }
          }
        }
      }
        
        `,
        queryVariables: {
          sku: product?.product?.sku,
        },
      })) as any;

      console.log(
        "configuralOptions details of product --->",
        configuralOptions.data
      );
      if (configuralOptions.data?.products?.items[0]?.configurable_options) {
        editCart.value = {
          ...product,
          configuralAvailableOptions: [
            ...configuralOptions.data?.products?.items[0]?.configurable_options,
            ...(Array.isArray(
              configuralOptions.data?.products?.items[0]?.options
            )
              ? configuralOptions.data?.products?.items[0]?.options.map(
                  (item) => {
                    return {
                      attribute_code: item?.option_id,
                      attribute_id_v2: item?.option_id,
                      attribute_uid: item?.uid,
                      id: item?.uid,
                      label: item?.title,
                      position: item?.option_id,
                      product_id: item?.option_id,
                      use_default: item?.required,
                      values: item?.value.map((el, pr_index) => {
                        return {
                          label: el?.title,
                          uid: el?.uid,
                          value_index: pr_index,
                        };
                      }),
                    };
                  }
                )
              : []),
          ],
          media_gallery:
            configuralOptions.data?.products?.items[0]?.media_gallery || [],
        };
      }

      updateLoader.value = false;
      setTimeout(() => {
        scrollableDiv.value.forEach((_, index) => checkScrollState(index));
      }, 1000);
      console.log("Reading configural Info ---->", editCart.value);
    };

    const nextSlideImage = () => {
      if (editCart.value?.media_gallery) {
        if (updateSliderIndex.value < editCart.value.media_gallery.length - 1) {
          updateSliderIndex.value++;
        } else {
          console.log("Reached the last slide");
          return;
        }
      }
    };

    const prevSlideImage = () => {
      if (editCart.value?.media_gallery) {
        if (updateSliderIndex.value > 0) {
          updateSliderIndex.value--;
        } else {
          console.log("Reached the first slide");
          return;
        }
      }
    };

    const goToProductDetails = () => {
      router.push({
        path: app.localePath(`/${editCart.value?.product?.url_key + ".html"}`),
      });
      editCart.value = null;
    };

    const selectedSize = async (
      label: string,
      value: string,
      selected: string
    ) => {
      console.log("Reading selected option ---->", value);
      // Ensure reactivity when updating `selectedSizeOption`
      selectedSizeOption.value = {
        ...selectedSizeOption.value, // Spread the current properties
        [label]: value, // Dynamically update the specific label
      };
      let productConfiguration = {
        ...product_configuration.value,
      };
      productConfiguration[label] = value;

      console.log(
        "Checking updating selectedSizeOption ---->",
        selectedSizeOption.value
      );
      product_configuration.value = productConfiguration;
      console.log("productConfiguration --->", productConfiguration);
      const query = {
        query: {
          filter: {
            sku: {
              eq: editCart.value.product.sku,
            },
          },
          configurations: Object.entries(productConfiguration).map(
            (config) => config[1]
          ) as string[],
        },
      };

      const result = (await app.$vsf.$magento.api.customQuery({
        query: `
      query getProductPriceBySku(
        $filter: ProductAttributeFilterInput,
        $configurations: [ID!]
      ) {
        products(filter: $filter) {
          items {
            price_range {
              ...PriceRangeFields
            }

            ... on ConfigurableProduct {
              price_range {
                maximum_price {
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
                minimum_price {
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
              }

              configurable_product_options_selection(configurableOptionValueUids: $configurations) {
                options_available_for_selection {
                  attribute_code
                  option_value_uids
                }
                media_gallery {
                  disabled
                  label
                  position
                  url
                }
                variant {
                  uid
                  sku
                  name
                  price_range {
                    ...PriceRangeFields
                  }
                }
              }
            }

            ... on BundleProduct {
              items {
                position
                required
                sku
                title
                type
                uid
                options {
                  can_change_quantity
                  is_default
                  position
                  uid
                  quantity
                  product {
                    uid
                    sku
                    name
                    price_range {
                      ...PriceRangeFields
                    }
                  }
                }
              }
            }

            ... on GroupedProduct {
              items {
                position
                qty
                product {
                  uid
                  sku
                  name
                  stock_status
                  
                  price_range {
                    maximum_price {
                      final_price {
                        currency
                        value
                      }
                      regular_price {
                        currency
                        value
                      }
                    }
                    minimum_price {
                      final_price {
                        currency
                        value
                      }
                      regular_price {
                        currency
                        value
                      }
                    }
                  }
                  thumbnail {
                    url
                    position
                    disabled
                    label
                  }
                }
              }
            }
          }
        }
      }

      fragment PriceRangeFields on PriceRange {
        maximum_price {
          final_price {
            currency
            value
          }
          regular_price {
            currency
            value
          }
        }
        minimum_price {
          final_price {
            currency
            value
          }
          regular_price {
            currency
            value
          }
        }
      }
    `,
        queryVariables: query.query,
      })) as any;

      if (
        result.data?.products?.items[0]?.configurable_product_options_selection
          ?.variant?.price_range
      ) {
        editCart.value.product.price_range =
          result.data?.products?.items[0]?.configurable_product_options_selection?.variant?.price_range;
      }
    };

    const updateProductSize = async () => {
      if (
        product_configuration.value &&
        Object.keys(selectedSizeOption.value).length > 0 &&
        Object.keys(selectedSizeOption.value).length ==
          (editCart.value &&
            editCart.value?.configuralAvailableOptions?.length) &&
        previous_product.value
      ) {
        try {
          const cutomerHeaders = {};
          await addItem({
            product: editCart.value.product,
            quantity: edit_product_quantity.value,
            productConfiguration: product_configuration.value,
            customQuery: {
              query: ``,
            },
            customHeaders: cutomerHeaders,
            not_to_show: "true",
          });
        } catch (error) {
        } finally {
          console.log("cartError while adding product ---->", cartError.value);
          if (cartError.value?.addItem) {
            product_configuration.value = null;
            edit_product_quantity.value = 1;
            selectedSizeOption.value = {};
            editCart.value = null;
            return;
          }
          try {
            const excludeUids =
              previous_product.value?.configurable_options?.map(
                (option) => option?.configurable_product_option_value_uid
              );
            const configKeys = Object.keys(product_configuration.value);
            console.log(
              "product_configuration.value --->",
              product_configuration.value,
              excludeUids
            );
            const configLength = configKeys.length;
            const requiredLength =
              editCart?.value?.configuralAvailableOptions.length;
            // Check if the values exist in excludeUids and count matches the requiredLength
            const matchingCount = configKeys.filter((key) =>
              excludeUids.includes(product_configuration.value[key])
            ).length;

            console.log(
              "Checking matching counts --->",
              matchingCount,
              requiredLength
            );

            if (matchingCount !== requiredLength) {
              await cartView.removeItemAndSendNotification(
                previous_product.value,
                "true"
              );
            }
          } catch (error) {
            console.log(
              "Error while removing product before updating ---->",
              error
            );
          } finally {
            product_configuration.value = null;
            edit_product_quantity.value = 1;
            selectedSizeOption.value = {};
            editCart.value = null;
          }
        }
      } else {
        if (
          editCart &&
          editCart.value?.configuralAvailableOptions?.length > 1
        ) {
          chooseOptionError.value =
            "Please choose one option in available options";
        } else {
          chooseOptionError.value = "Please choose one option";
        }
      }
    };

    watch(selectedSizeOption, () => {
      console.log(
        "Object.keys(selectedSizeOption.value).length --->",
        Object.keys(selectedSizeOption.value).length
      );
      if (
        Object.keys(selectedSizeOption.value).length ==
        (editCart.value && editCart.value?.configuralAvailableOptions?.length)
      ) {
        chooseOptionError.value = "";
      }
    });

    const redirectToSearch = () => {
      router.push(
        app.localeRoute({ path: "/search" + "?term=" + searchTerm.value })
      );
      searchInput.value = false;
      searchTerm.value = "";
    };

    watch(
      () => editCart.value?.configuralAvailableOptions,
      (newOptions) => {
        if (newOptions && Array.isArray(newOptions)) {
          newOptions.forEach((_, index) => {
            scrollStates.value = {
              ...scrollStates.value,
              [index]: {
                atStart: false,
                atEnd: false,
                isScrollable: true,
              },
            };
          });
          console.log("scrollStates updated based on options:", scrollStates);
        }
      },
      { immediate: true }
    );

    return {
      accountIcon,
      redirectToSearch,
      searchItemsDropdown,
      searchProductHandler,
      searchFromSuggestion,
      redirectToWishList,
      updateCompareCart,
      searchResults,
      compareCart,
      updateSliderIndex,
      prevSlideImage,
      nextSlideImage,
      updateWishCart,
      updateShoppingCart,
      wishCart,
      goToCart,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      handleAccountClick,
      toggleLoginModal,
      isAuthenticated,
      search_loader,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
      categoryDropdown,
      trackMouseEnter,
      currentCategory,
      isModalVisible,
      shoppingCart,
      ...cartView,
      getScrollState,
      proceedToCheckout,
      wishlistProducts,
      removeItem,
      wishloader,
      updateSearch,
      searchInput,
      searchTerm,
      suggested_searches,
      compare_list,
      totalCompareCount: computed(() => compare_list.value.length),
      removeProductFromCompareList,
      redirectToCompareList,
      offerDropdown,
      toggleOfferDropdown,
      shoppingCartRef,
      wishCartRef,
      compareCartRef,
      editCart,
      updateEdit,
      updateLoader,
      goToProductDetails,
      scrollableDiv,
      scrollRight,
      scrollLeft,
      checkScrollState,
      scrollStates,
      selectedSizeOption,
      selectedSize,
      isCartLoading,
      updateProductSize,
      chooseOptionError,
      config,
      showDialog,
      handleConfirm,
      handleCancel,
      deleteProduct,
      delete_product,
      logo_src,
      searchTotalCount,
      clearCompare,
      isHeaderFixed,
    };
  },
});
