import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=8893cda4&scoped=true&"
import script from "./AppHeader.vue?vue&type=script&lang=ts&"
export * from "./AppHeader.vue?vue&type=script&lang=ts&"
import style0 from "./AppHeader.vue?vue&type=style&index=0&id=8893cda4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8893cda4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CategoryDropdownComponent: require('/var/www/domains/bedquarter.com/public_html/components/dropdowncomponent/CategoryDropdownComponent.vue').default,Loader: require('/var/www/domains/bedquarter.com/public_html/components/Loader.vue').default,ConfirmationDialog: require('/var/www/domains/bedquarter.com/public_html/components/ConfirmationDialog.vue').default})
