//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import {
    defineComponent,
    ref,
    useContext
  } from '@nuxtjs/composition-api';
  

  
  export default defineComponent({
    name: 'CategoryDropdownComponent',
    props: {
      currentCategory: {
        type: Object,
        default: null,
      }
    },
    setup(props) {

        const currentCategory = ref(props.currentCategory);
        const {app} = useContext();

        const validateThumbnailImageUrl = (imageUrl) => {
  const baseDomain = process.env.VSF_MAGENTO_BASE_URL; // Replace with your actual domain
  
  if (!imageUrl) return ''; // Handle null/undefined imageUrl
  
  // Extract domain from imageUrl if it exists
  const domainRegex = /^(https?:\/\/[^/]+)(.*)$/; 
  const match = imageUrl.match(domainRegex);

  if (match) {
    const [, domain, path] = match;
    // If the domain doesn't match the base domain, replace it with the correct one
    if (domain !== baseDomain) {
      return `${baseDomain}${path}`;
    }
    return imageUrl; // Domain is already correct
  }

  // If no domain is present in the URL, prepend the base domain
  return `${baseDomain}${imageUrl.startsWith('/') ? imageUrl : `/${imageUrl}`}`;
};

      return {
        currentCategory,
        validateThumbnailImageUrl,
        localPrefix:app.i18n.locale !== app.i18n.defaultLocale ? `/${app.i18n.locale}` : '',
      };
    },
  });
  