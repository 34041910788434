





















































































































































































































































































import { SfOverlay, SfHeader, SfButton, SfBadge } from "@storefront-ui/vue";
import { useCategory } from "~/modules/catalog/category/composables/useCategory";
import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
  watch,
} from "@nuxtjs/composition-api";
import HeaderNavigation from "~/components/Header/Navigation/HeaderNavigation.vue";
import {
  useCartView,
  useProduct,
  useUiHelpers,
  useUiState,
} from "~/composables";
import { useCart } from "~/modules/checkout/composables/useCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useUser } from "~/modules/customer/composables/useUser";
import { useWishlistStore } from "~/modules/wishlist/store/wishlistStore";
import type {
  CategoryTree,
  ProductInterface,
  WishlistItemInterface,
} from "~/modules/GraphQL/types";
import HeaderLogo from "~/components/HeaderLogo.vue";
import SvgImage from "~/components/General/SvgImage.vue";
import { useTopBar } from "./TopBar/useTopBar";
import CategoryDropdownComponent from "~/components/dropdowncomponent/CategoryDropdownComponent.vue";
import SignInModal from "./Modal/SignInModal.vue";
import { availableStoreCountries, countryCodeMap, determineLanguage, determineStore, languageSwitcher, main_slider_ids, replacePartInURL } from "~/utils/HomePageIdentifiers";

export default defineComponent({
  components: {
    HeaderNavigation,
    CategoryDropdownComponent,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    SignInModal,
    CurrencySelector: () => import("~/components/CurrencySelector.vue"),
    StoreSwitcher: () => import("~/components/StoreSwitcher.vue"),
    SearchBar: () => import("~/components/Header/SearchBar/SearchBar.vue"),
    SearchResults: () =>
      import(
        /* webpackPrefetch: true */ "~/components/Header/SearchBar/SearchResults.vue"
      ),
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { getProductList, getProductPath } = useProduct();

    const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal } =
      useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const {
      loadItemsCount: loadWishlistItemsCount,
      loading: wishloader,
      removeItem,
      load: loadWishlist,
    } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } =
      useCategory();
    const currentCategory = ref<CategoryTree | null>(null);
    const shoppingCart = ref(false);
    const wishCart = ref(false);
    const compareCart = ref(false);
    const searchTerm = ref("");
    const searchInput = ref(false);
    const searchItemsDropdown = ref(false);
    const searchResults = ref<any>(null);
    const search_loader = ref(false);
    const mobile_menu = ref(false);
    const searchTotalCount = ref(0);

    const selectedLanguage = ref({ name: 'عربي',code:'ar' });
    const dropdownOpen = ref(false);
    const selectedCountry = ref({
      name: "Saudi Arabia",
      flag: '',
    });
    const filteredCountries = computed(() => {
      return availableStoreCountries.filter(
        (country) => country.name !== selectedCountry.value.name
      );
    });

    const cartView = useCartView();
    const sliderInfo = ref<any>({
      MagicSlider:{
        media_gallery: []
      }
    });

    const selectedSlide = ref<any>({
      btntitle:'',
      bnUrl:'',
      bnContent:'',
      bnImg:'',
      bnImg2:'',
      short:''
    });

    const isModalVisible = ref(false);


    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();
    const categoryDropdown = ref(false);
    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const activeSlideIndex = ref(0);
    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(
      () => wishlistStore.wishlist?.items_count ?? 0
    );

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);

    const wishlistProducts = computed<WishlistItemInterface[]>(
      () => wishlistStore.wishlist.items_v2?.items ?? []
    );

    

    watch(searchTerm, async (newText) => {
      if (searchTerm.value.trim().length > 0) {
        searchItemsDropdown.value = true;
        search_loader.value = true;
        const data = await getProductList({ search: newText });
        searchResults.value = data.items;
        searchTotalCount.value = data.total_count ?? 0;
        if (data.items) {
          search_loader.value = false;
        }
      } else {
        searchItemsDropdown.value = false;
        searchResults.value = null;
      }
    });

    const accountIcon = computed(() =>
      isAuthenticated.value ? "profile_fill" : "profile"
    );
    const categoryTree = ref<CategoryTree[]>([]);

    const handleAccountClick = async () => {
      try {
        if (isAuthenticated.value) {
          // If the user is authenticated, navigate to the profile page
          await router.push(app.localeRoute({ name: "customer-my-profile" }));
        } else {
          await router.push(app.localeRoute({ name: "signin" }));
        }
      } catch (error) {
        console.error("Error during navigation:", error);
      }
    };

    const setCurrentCategory = (category: CategoryTree | null) => {
      currentCategory.value = category;
    };
    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value
        .filter((category) => category.include_in_menu)
        .slice(0, 4);
    });

    onMounted(async () => {
      await loadWishlist({ searchParams: { pageSize: 100 } });
    });

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
    });
    onMounted(() => {
      selectedLanguage.value = determineLanguage(app.i18n.locale);
      selectedCountry.value = determineStore(app.i18n.locale);

    });
    // const selectCountry = (country) => {
    //   selectedCountry.value = country;
    //   dropdownOpen.value = false;
    //   const currentURL = window.location.href;

    //   const languageCode =
    //     selectedLanguage.value.name === 'إنجليزي' ? "Arabic" : "English";
    //   const routeCode = countryCodeMap[country.name][languageCode];

    //   if (routeCode) {
    //     const route = replacePartInURL(currentURL, app.i18n.locale, routeCode);
    //     window.location.href = route;
    //   }
    // };
    // Track mouse movement to detect if it's moving up or sideways
    const trackMouseEnter = (item) => {
      categoryDropdown.value = true;
      setCurrentCategory(item);
    };

    const selectCountry = (country) => {
  console.log("Check selected country ---->", country);

  // Get the current URL and extract the base domain
  const currentURL = window.location.href;
  const baseURL = currentURL.split('/').slice(0, 3).join('/'); // Extract base domain (e.g., http://localhost:3000)

  // Define the current language based on selectedLanguage.value
  const languageCode =
    selectedLanguage.value.name === 'إنجليزي' ? 'ar' : 'en';

  // Function to sanitize the path
  const sanitizePath = (path) => {

    if (app.i18n.locale === 'ksa_en') {
      // If on default store, remove only dynamic prefixes, keep the rest of the path
      return path.replace(/^([^/]+_)/, '').replace(/^\/+/, ''); // Remove leading dynamic prefix and clean up leading slashes
    }

    if(country.name == 'Saudi Arabia'){
      return  path.replace(/^([^/]+_)?[^/]+/, '').replace(/^\/+/, ''); // Remove leading slashes only
    }
    // Remove dynamic prefixes and leading slashes
    return path.replace(/^([^/]+_)?[^/]+/, '').replace(/^\/+/, '');
  };

  // Extract and sanitize the current path
  const currentPath = currentURL.split('/').slice(3).join('/'); // Extract path after domain
  const sanitizedPath = sanitizePath(currentPath);
  console.log("Sanitized Path ---->", sanitizedPath);

  // Check if the selected country is "Saudi Arabia"
  if (country.name === 'Saudi Arabia') {
    // Redirect to the base URL with the sanitized path
    const updatedURL = sanitizedPath ? `${baseURL}${languageCode == 'ar' ? '/ksa_ar' : ''}/${sanitizedPath}` : baseURL + (languageCode == 'ar' ? '/ksa_ar' : '');
    console.log("Updated URL for Saudi Arabia:", updatedURL);
    window.location.href = updatedURL; // Uncomment for actual redirection
    return;
  }

  // For other countries, add the dynamic prefix based on the selected country and language
  const countryCodeMap = {
    'United Arab Emirates': 'uae',
    'Oman': 'omn',
    'Bahrain': 'bhr',
    // Add other countries as needed
  };

  const countryPrefix = countryCodeMap[country.name];
  if (countryPrefix) {
    // Construct the new URL with the selected country and language
    const updatedURL = sanitizedPath
      ? `${baseURL}/${countryPrefix}_${languageCode}/${sanitizedPath}`
      : `${baseURL}/${countryPrefix}_${languageCode}`;
    console.log("Updated URL for other countries:", updatedURL);
    window.location.href = updatedURL; // Uncomment for actual redirection
  }
};

    const goToCart = async () => {
      shoppingCart.value = false;
      await router.push(app.localeRoute({ name: "cart" }));
    };

    const proceedToCheckout = async () => {
      shoppingCart.value = false;
      cartView.goToCheckout();
    };

    const updateShoppingCart = () => {
      if (shoppingCart.value) {
        shoppingCart.value = false;
      } else {
        shoppingCart.value = true;
        wishCart.value = false;
        compareCart.value = false;
      }
    };
    const updateWishCart = () => {
      if (wishCart.value) {
        wishCart.value = false;
      } else {
        wishCart.value = true;
        shoppingCart.value = false;
        compareCart.value = false;
      }
    };
    const updateCompareCart = () => {
      if (compareCart.value) {
        compareCart.value = false;
      } else {
        compareCart.value = true;
        wishCart.value = false;
        shoppingCart.value = false;
      }
    };

    const updateSearch = () => {
      searchInput.value = true;
      wishCart.value = false;
      shoppingCart.value = false;
      compareCart.value = false;
    };

    const redirectToWishList = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      router.push(app.localeRoute({ name: "customer-my-wishlist" }));
      wishCart.value = false;
    };
    const searchProductHandler = (product: any) => {
      const url = getProductPath(product);
      router.push(app.localeRoute(url));
      searchItemsDropdown.value = false;
      searchInput.value = false;
      wishCart.value = false;
      shoppingCart.value = false;
      compareCart.value = false;
      searchTerm.value = "";
    };
    // const selectLanguage = (lng) => {
    //   selectedLanguage.value = {
    //     name:lng,
    //     code : lng === 'إنجليزي' ? "en" : "ar",
    //   };
    //   const route = languageSwitcher(app.i18n.locale);
    //   const currentURL = window.location.href;
    //   const newURL = currentURL.replace(/([a-z]{3}_[a-z]{2})/, route);
    //   window.history.replaceState(null, null, newURL);
    //   window.location.reload();
    // }

    const selectLanguage = (lng) => {
  // Set the selected language
  selectedLanguage.value = {
    name: lng,
    code: lng === 'إنجليزي' ? 'en' : 'ar',
  };
  app.$cookies.remove('vsf-store', { path: '/' });
  app.$cookies.remove('vsf-locale', { path: '/' });
  // Determine the new route using the language switcher
  const route = languageSwitcher(app.i18n.locale);

  // Get the current URL
  const currentURL = window.location.href;

  let newURL;
  if (app.i18n.locale === 'ksa_en' && route === 'ksa_ar') {
    // If switching from `ksa_en` to `ksa_ar`, add the prefix `ksa_ar`
    const baseURL = currentURL.split('/').slice(0, 3).join('/'); // Extract the domain
    const path = currentURL.split('/').slice(3).join('/'); // Extract the path after domain
    newURL = `${baseURL}/ksa_ar${path ? `/${path}` : ''}`; // Add '/ksa_ar/' prefix to the path
    console.log("While switching to ksa_ar", newURL);
      // Update the browser's URL and reload
    window.history.replaceState(null, null, newURL);
    window.location.reload();
  } else if (route === 'ksa_en') {
    // If switching to `ksa_en`, avoid creating a new URL if already in default state
    if (currentURL.includes('/ksa_ar') || currentURL.match(/([a-z]{3}_[a-z]{2})/)) {
      // If the current URL includes `/ksa_ar` or another prefix, clean it
      const baseURL = currentURL.split('/').slice(0, 3).join('/'); // Extract the domain
      const path = currentURL.split('/').slice(3).join('/'); // Extract the path after domain
      newURL = `${baseURL}/${path.replace(/^([a-z]{3}_[a-z]{2})\//, '')}`; // Remove any existing prefix
      console.log("While switching to ksa_en 111", newURL.includes('/ksa_ar') ? newURL.replace('/ksa_ar','') : newURL.replace('/ksa_ar','/'));
      // Update the browser's URL and reload
      window.history.replaceState(null, null, newURL.replace('/ksa_ar',''));
      window.location.reload();
    } else {
      // Already on default `ksa_en`, no URL change needed
      console.log("Already on default ksa_en, no URL change needed.");
      return;
    }
  } else {
    // Replace the existing language prefix with the new one for other routes
    newURL = currentURL.replace(/([a-z]{3}_[a-z]{2})/, route);
    console.log("While switching to all others store ---->", newURL);
    // Update the browser's URL and reload
  window.history.replaceState(null, null, newURL);
  window.location.reload();
  return
  }

  
};
    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value;
    };


    onMounted( async () => {
      const read_slider = await app.$vsf.$magento.api.customQuery({
        query: `
        query {
          MagicSlider(id: "${main_slider_ids(app.i18n.locale)}") {
            title
            media_gallery {
              
              disabled
              bnType
              bnTitle
              short
              bnImg2
              btntitle
              bnUrl
              bnImg
              bnContent
              newtab
            }
          }
        }`,

        customHeaders:{
         'store': app.i18n.locale,
        }

      });

      


      sliderInfo.value = read_slider.data;
     
      if(sliderInfo.value.MagicSlider.media_gallery.length > 0){
        selectedSlide.value = sliderInfo.value.MagicSlider.media_gallery[0];
      }

      console.log("selectedSlide ----->",selectedSlide.value)

    });

    const handleImageError = (event) => {
      event.target.src = require("@/assets/images/small_screen_slider_image.svg");
    }

    const swipeRight = () => {
  const mediaGallery = sliderInfo.value.MagicSlider.media_gallery;
  if (mediaGallery.length > 0) {
    // Increment the index or reset to 0 if at the last slide
    const currentIndex = mediaGallery.indexOf(selectedSlide.value);
    const nextIndex = (currentIndex + 1) % mediaGallery.length;
    selectedSlide.value = mediaGallery[nextIndex];
    console.log("Next Slide: ", selectedSlide.value);
    activeSlideIndex.value = nextIndex;
  }
};

const swipeLeft = () => {
  const mediaGallery = sliderInfo.value.MagicSlider.media_gallery;
  if (mediaGallery.length > 0) {
    // Decrement the index or reset to the last slide if at the first slide
    const currentIndex = mediaGallery.indexOf(selectedSlide.value);
    const prevIndex = (currentIndex - 1 + mediaGallery.length) % mediaGallery.length;
    selectedSlide.value = mediaGallery[prevIndex];
    console.log("Previous Slide: ", selectedSlide.value);
    activeSlideIndex.value = prevIndex;

  }
};
const openSlide = (slide,i) => {
  selectedSlide.value = slide;  
  activeSlideIndex.value = i
}

const redirectToSearch = () => {
      router.push(app.localeRoute({ path: "/search" + "?term=" + searchTerm.value }));
      searchInput.value = false;
      searchTerm.value = "";
    }

    return {
      accountIcon,
      swipeRight,
      redirectToSearch,
      openSlide,
      activeSlideIndex,
      swipeLeft,
      handleImageError,
      searchItemsDropdown,
      searchProductHandler,
      redirectToWishList,
      updateCompareCart,
      searchResults,
      compareCart,
      updateWishCart,
      updateShoppingCart,
      wishCart,
      goToCart,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      handleAccountClick,
      toggleLoginModal,
      isAuthenticated,
      search_loader,
      isSearchOpen,
      searchTotalCount,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
      categoryDropdown,
      trackMouseEnter,
      currentCategory,
      isModalVisible,
      shoppingCart,
      ...cartView,
      proceedToCheckout,
      wishlistProducts,
      removeItem,
      wishloader,
      updateSearch,
      searchInput,
      searchTerm,
      setCurrentCategory,
      mobile_menu,
      selectedLanguage,
      selectLanguage,
      toggleDropdown,
      filteredCountries,
      selectCountry,
      selectedCountry,
      dropdownOpen,
      selectedSlide,
      sliderInfo
    };
  },
});
