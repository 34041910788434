import { UseContextReturn } from '~/types/core';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import {getProductDetailsQuery} from '~/modules/catalog/product/queries/getProductDetails.gql';

export const getProductDetailsCommand = {
  execute: async (context: UseContextReturn, searchParams: any, customQuery = { productDetails: `productDetails` }, customHeaders = {}) => {
   
   // comment out magento api for to customizing the query
  // const { data } = await context.app.$vsf.$magento.api.productDetails(searchParams, customQuery, customHeaders);
  const  customHeaderss = {
    'store':context.app.i18n.locale,
    ...customHeaders,
  }
    const {data:info,errors } = await context.app.$vsf.$magento.api.customQuery({
      query: getProductDetailsQuery,
      queryVariables:searchParams,
      customHeaders:customHeaderss
    });


    console.log("Reading error while get product details",errors);
    console.log("Reading info while get product details",info);


    return (info as any)?.products ?? null;
  },
};
