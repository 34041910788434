































import LazyHydrate from "vue-lazy-hydration";
import {
  useRoute,
  useContext,
  defineComponent,
  onMounted,
  computed,
  watch,
} from "@nuxtjs/composition-api";
import { useCart, useConfig, useUiState, useWishlist } from "~/composables";
import AppHeader from "~/components/AppHeader.vue";
import BottomNavigation from "~/components/BottomNavigation.vue";
import IconSprite from "~/components/General/IconSprite.vue";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import TopBar from "~/components/TopBar/TopBar.vue";
import MobileHeader from "~/components/MobileHeader.vue";
import PagesHeader from "~/components/PagesHeader.vue";
import useCompare from "~/composables/useCompare";
import Loader from "~/components/Loader.vue";
import { determineStoreCountry } from "~/utils/HomePageIdentifiers";
import MainPopup from "~/components/Popup/MainPopup.vue";


export default defineComponent({
  name: "DefaultLayout",
  middleware:['redirect-url-resolver'],
  components: {
    LoadWhenVisible,
    LazyHydrate,
    MainPopup,
    AppHeader,
    PagesHeader,
    Loader,
    BottomNavigation,
    IconSprite,
    TopBar,
    MobileHeader,
    AppFooter: () =>
      import(/* webpackPrefetch: true */ "~/components/AppFooter.vue"),
    CartSidebar: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/checkout/components/CartSidebar.vue"
      ),
    WishlistSidebar: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/wishlist/components/WishlistSidebar.vue"
      ),
    LoginModal: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/customer/components/LoginModal/LoginModal.vue"
      ),
    Notification: () =>
      import(/* webpackPrefetch: true */ "~/components/Notification.vue"),
  },

  setup() {
    const route = useRoute();
    const { app } = useContext();
    const { loading: compareLoading } = useCompare();
    const { loading: wishlistLoading } = useWishlist();
    const { load: loadConfig,config } = useConfig();
    const {load} = useCart();
    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
    } = useUiState();

    

   

    onMounted(() => {
      if (app.i18n.localeProperties.file === "ar.js") {
        document
          .getElementsByTagName("body")[0]
          .classList.add("arabic_integration");
      } else {
        document.documentElement.lang = "ar";
      }
    });

    onMounted( async () => {
     await loadConfig();
     await load()
     const reading_country = determineStoreCountry(app.i18n.locale);

     try {
      app.$vsf.$magento.config.state.setCountry(reading_country);
     } catch (error) {
        console.log("Error while updating country", error);
     }

     const container = document.createElement('div');
      container.innerHTML = (config.value as any)?.head_includes;

      // Extract all child nodes (script, style, etc.) and append them to <head>
      Array.from(container.childNodes).forEach((node) => {
        document.head.appendChild(node);
      });

      let robotsMetaTag = document.querySelector('meta[name="robots"]');
      if (!robotsMetaTag) {
        robotsMetaTag = document.createElement("meta");
        robotsMetaTag.setAttribute("name", "robots");
        document.head.appendChild(robotsMetaTag);
      }
      robotsMetaTag.setAttribute("content", (config.value as any)?.default_robots);

    })

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
      compareLoading: computed(() => compareLoading.value),
      wishlistLoading: computed(() => wishlistLoading.value),
    };
  },
  head: {
    link: [
      { rel: "stylesheet", href: "/_nuxt/fonts.css" },

      {
        rel: "stylesheet",
        href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css",
      },
    ],
  },
});
