import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _693c16f0 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _08cf0116 = () => interopDefault(import('../modules/custom/pages/BrandsListing.vue' /* webpackChunkName: "" */))
const _eec77144 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _2ce358f8 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _6d37ce60 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _68136adb = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _ba4d90ae = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _e7aafda6 = () => interopDefault(import('../modules/checkout/pages/Checkout/ShippingAndBilling.vue' /* webpackChunkName: "" */))
const _07db7037 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _1abd86a0 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _ead892fc = () => interopDefault(import('../modules/custom/pages/Compare.vue' /* webpackChunkName: "" */))
const _6fb0545d = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _ded1166a = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _2819e5b9 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _6e223087 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _e3abd16a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _364dcac5 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _99a633ea = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _bf4eaff4 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _2f842452 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _2f84dde3 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _19c22ff0 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _40b74b78 = () => interopDefault(import('../modules/checkout/pages/Checkout/Failed.vue' /* webpackChunkName: "" */))
const _1b970a38 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _252c7e1b = () => interopDefault(import('../modules/custom/pages/Search.vue' /* webpackChunkName: "" */))
const _5b72c23e = () => interopDefault(import('../modules/auth/pages/Signin.vue' /* webpackChunkName: "" */))
const _700918b4 = () => interopDefault(import('../modules/auth/pages/Signup.vue' /* webpackChunkName: "" */))
const _77ddb1be = () => interopDefault(import('../modules/custom/pages/Store.vue' /* webpackChunkName: "" */))
const _e731f960 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _bb06e782 = () => interopDefault(import('../modules/custom/pages/Brands.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bhr_ar",
    component: _693c16f0,
    name: "home___bhr_ar"
  }, {
    path: "/bhr_en",
    component: _693c16f0,
    name: "home___bhr_en"
  }, {
    path: "/brands",
    component: _08cf0116,
    name: "brand___ksa_en"
  }, {
    path: "/cart",
    component: _eec77144,
    name: "cart___ksa_en"
  }, {
    path: "/checkout",
    component: _2ce358f8,
    name: "checkout___ksa_en",
    children: [{
      path: "billing",
      component: _6d37ce60,
      name: "billing___ksa_en"
    }, {
      path: "payment",
      component: _68136adb,
      name: "payment___ksa_en"
    }, {
      path: "shipping",
      component: _ba4d90ae,
      name: "shipping___ksa_en"
    }, {
      path: "shipping-and-billing",
      component: _e7aafda6,
      name: "shipping-and-billing___ksa_en"
    }, {
      path: "user-account",
      component: _07db7037,
      name: "user-account___ksa_en"
    }]
  }, {
    path: "/Cms",
    component: _1abd86a0,
    name: "Cms___ksa_en"
  }, {
    path: "/compare",
    component: _ead892fc,
    name: "compare___ksa_en"
  }, {
    path: "/customer",
    component: _6fb0545d,
    meta: {"titleLabel":"My Account"},
    name: "customer___ksa_en",
    children: [{
      path: "addresses-details",
      component: _ded1166a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___ksa_en"
    }, {
      path: "my-newsletter",
      component: _2819e5b9,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___ksa_en"
    }, {
      path: "my-profile",
      component: _6e223087,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___ksa_en"
    }, {
      path: "my-reviews",
      component: _e3abd16a,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___ksa_en"
    }, {
      path: "my-wishlist",
      component: _364dcac5,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___ksa_en"
    }, {
      path: "order-history",
      component: _99a633ea,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___ksa_en"
    }, {
      path: "/reset-password",
      component: _bf4eaff4,
      alias: "/customer/account/createPassword",
      name: "reset-password___ksa_en"
    }, {
      path: "addresses-details/create",
      component: _2f842452,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___ksa_en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2f84dde3,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___ksa_en"
    }, {
      path: "order-history/:orderId",
      component: _19c22ff0,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___ksa_en"
    }]
  }, {
    path: "/failed-order",
    component: _40b74b78,
    name: "failed-order___ksa_en"
  }, {
    path: "/Home",
    component: _693c16f0,
    name: "Home___ksa_en"
  }, {
    path: "/ksa_ar",
    component: _693c16f0,
    name: "home___ksa_ar"
  }, {
    path: "/omn_ar",
    component: _693c16f0,
    name: "home___omn_ar"
  }, {
    path: "/omn_en",
    component: _693c16f0,
    name: "home___omn_en"
  }, {
    path: "/Page",
    component: _1b970a38,
    name: "Page___ksa_en"
  }, {
    path: "/search",
    component: _252c7e1b,
    name: "search___ksa_en"
  }, {
    path: "/signin",
    component: _5b72c23e,
    name: "signin___ksa_en"
  }, {
    path: "/signup",
    component: _700918b4,
    name: "auth___ksa_en"
  }, {
    path: "/store-locations",
    component: _77ddb1be,
    name: "store___ksa_en"
  }, {
    path: "/thank-you",
    component: _e731f960,
    name: "thank-you___ksa_en"
  }, {
    path: "/uae_ar",
    component: _693c16f0,
    name: "home___uae_ar"
  }, {
    path: "/uae_en",
    component: _693c16f0,
    name: "home___uae_en"
  }, {
    path: "/bhr_ar/brands",
    component: _08cf0116,
    name: "brand___bhr_ar"
  }, {
    path: "/bhr_ar/cart",
    component: _eec77144,
    name: "cart___bhr_ar"
  }, {
    path: "/bhr_ar/checkout",
    component: _2ce358f8,
    name: "checkout___bhr_ar",
    children: [{
      path: "billing",
      component: _6d37ce60,
      name: "billing___bhr_ar"
    }, {
      path: "payment",
      component: _68136adb,
      name: "payment___bhr_ar"
    }, {
      path: "shipping",
      component: _ba4d90ae,
      name: "shipping___bhr_ar"
    }, {
      path: "shipping-and-billing",
      component: _e7aafda6,
      name: "shipping-and-billing___bhr_ar"
    }, {
      path: "user-account",
      component: _07db7037,
      name: "user-account___bhr_ar"
    }]
  }, {
    path: "/bhr_ar/Cms",
    component: _1abd86a0,
    name: "Cms___bhr_ar"
  }, {
    path: "/bhr_ar/compare",
    component: _ead892fc,
    name: "compare___bhr_ar"
  }, {
    path: "/bhr_ar/customer",
    component: _6fb0545d,
    meta: {"titleLabel":"My Account"},
    name: "customer___bhr_ar",
    children: [{
      path: "addresses-details",
      component: _ded1166a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___bhr_ar"
    }, {
      path: "my-newsletter",
      component: _2819e5b9,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___bhr_ar"
    }, {
      path: "my-profile",
      component: _6e223087,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___bhr_ar"
    }, {
      path: "my-reviews",
      component: _e3abd16a,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___bhr_ar"
    }, {
      path: "my-wishlist",
      component: _364dcac5,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___bhr_ar"
    }, {
      path: "order-history",
      component: _99a633ea,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___bhr_ar"
    }, {
      path: "addresses-details/create",
      component: _2f842452,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___bhr_ar"
    }, {
      path: "/bhr_ar/reset-password",
      component: _bf4eaff4,
      alias: "/customer/account/createPassword",
      name: "reset-password___bhr_ar"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2f84dde3,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___bhr_ar"
    }, {
      path: "order-history/:orderId",
      component: _19c22ff0,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___bhr_ar"
    }]
  }, {
    path: "/bhr_ar/failed-order",
    component: _40b74b78,
    name: "failed-order___bhr_ar"
  }, {
    path: "/bhr_ar/Home",
    component: _693c16f0,
    name: "Home___bhr_ar"
  }, {
    path: "/bhr_ar/Page",
    component: _1b970a38,
    name: "Page___bhr_ar"
  }, {
    path: "/bhr_ar/search",
    component: _252c7e1b,
    name: "search___bhr_ar"
  }, {
    path: "/bhr_ar/signin",
    component: _5b72c23e,
    name: "signin___bhr_ar"
  }, {
    path: "/bhr_ar/signup",
    component: _700918b4,
    name: "auth___bhr_ar"
  }, {
    path: "/bhr_ar/store-locations",
    component: _77ddb1be,
    name: "store___bhr_ar"
  }, {
    path: "/bhr_ar/thank-you",
    component: _e731f960,
    name: "thank-you___bhr_ar"
  }, {
    path: "/bhr_en/brands",
    component: _08cf0116,
    name: "brand___bhr_en"
  }, {
    path: "/bhr_en/cart",
    component: _eec77144,
    name: "cart___bhr_en"
  }, {
    path: "/bhr_en/checkout",
    component: _2ce358f8,
    name: "checkout___bhr_en",
    children: [{
      path: "billing",
      component: _6d37ce60,
      name: "billing___bhr_en"
    }, {
      path: "payment",
      component: _68136adb,
      name: "payment___bhr_en"
    }, {
      path: "shipping",
      component: _ba4d90ae,
      name: "shipping___bhr_en"
    }, {
      path: "shipping-and-billing",
      component: _e7aafda6,
      name: "shipping-and-billing___bhr_en"
    }, {
      path: "user-account",
      component: _07db7037,
      name: "user-account___bhr_en"
    }]
  }, {
    path: "/bhr_en/Cms",
    component: _1abd86a0,
    name: "Cms___bhr_en"
  }, {
    path: "/bhr_en/compare",
    component: _ead892fc,
    name: "compare___bhr_en"
  }, {
    path: "/bhr_en/customer",
    component: _6fb0545d,
    meta: {"titleLabel":"My Account"},
    name: "customer___bhr_en",
    children: [{
      path: "addresses-details",
      component: _ded1166a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___bhr_en"
    }, {
      path: "my-newsletter",
      component: _2819e5b9,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___bhr_en"
    }, {
      path: "my-profile",
      component: _6e223087,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___bhr_en"
    }, {
      path: "my-reviews",
      component: _e3abd16a,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___bhr_en"
    }, {
      path: "my-wishlist",
      component: _364dcac5,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___bhr_en"
    }, {
      path: "order-history",
      component: _99a633ea,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___bhr_en"
    }, {
      path: "addresses-details/create",
      component: _2f842452,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___bhr_en"
    }, {
      path: "/bhr_en/reset-password",
      component: _bf4eaff4,
      alias: "/customer/account/createPassword",
      name: "reset-password___bhr_en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2f84dde3,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___bhr_en"
    }, {
      path: "order-history/:orderId",
      component: _19c22ff0,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___bhr_en"
    }]
  }, {
    path: "/bhr_en/failed-order",
    component: _40b74b78,
    name: "failed-order___bhr_en"
  }, {
    path: "/bhr_en/Home",
    component: _693c16f0,
    name: "Home___bhr_en"
  }, {
    path: "/bhr_en/Page",
    component: _1b970a38,
    name: "Page___bhr_en"
  }, {
    path: "/bhr_en/search",
    component: _252c7e1b,
    name: "search___bhr_en"
  }, {
    path: "/bhr_en/signin",
    component: _5b72c23e,
    name: "signin___bhr_en"
  }, {
    path: "/bhr_en/signup",
    component: _700918b4,
    name: "auth___bhr_en"
  }, {
    path: "/bhr_en/store-locations",
    component: _77ddb1be,
    name: "store___bhr_en"
  }, {
    path: "/bhr_en/thank-you",
    component: _e731f960,
    name: "thank-you___bhr_en"
  }, {
    path: "/ksa_ar/brands",
    component: _08cf0116,
    name: "brand___ksa_ar"
  }, {
    path: "/ksa_ar/cart",
    component: _eec77144,
    name: "cart___ksa_ar"
  }, {
    path: "/ksa_ar/checkout",
    component: _2ce358f8,
    name: "checkout___ksa_ar",
    children: [{
      path: "billing",
      component: _6d37ce60,
      name: "billing___ksa_ar"
    }, {
      path: "payment",
      component: _68136adb,
      name: "payment___ksa_ar"
    }, {
      path: "shipping",
      component: _ba4d90ae,
      name: "shipping___ksa_ar"
    }, {
      path: "shipping-and-billing",
      component: _e7aafda6,
      name: "shipping-and-billing___ksa_ar"
    }, {
      path: "user-account",
      component: _07db7037,
      name: "user-account___ksa_ar"
    }]
  }, {
    path: "/ksa_ar/Cms",
    component: _1abd86a0,
    name: "Cms___ksa_ar"
  }, {
    path: "/ksa_ar/compare",
    component: _ead892fc,
    name: "compare___ksa_ar"
  }, {
    path: "/ksa_ar/customer",
    component: _6fb0545d,
    meta: {"titleLabel":"My Account"},
    name: "customer___ksa_ar",
    children: [{
      path: "addresses-details",
      component: _ded1166a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___ksa_ar"
    }, {
      path: "my-newsletter",
      component: _2819e5b9,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___ksa_ar"
    }, {
      path: "my-profile",
      component: _6e223087,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___ksa_ar"
    }, {
      path: "my-reviews",
      component: _e3abd16a,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___ksa_ar"
    }, {
      path: "my-wishlist",
      component: _364dcac5,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___ksa_ar"
    }, {
      path: "order-history",
      component: _99a633ea,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___ksa_ar"
    }, {
      path: "addresses-details/create",
      component: _2f842452,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___ksa_ar"
    }, {
      path: "/ksa_ar/reset-password",
      component: _bf4eaff4,
      alias: "/customer/account/createPassword",
      name: "reset-password___ksa_ar"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2f84dde3,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___ksa_ar"
    }, {
      path: "order-history/:orderId",
      component: _19c22ff0,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___ksa_ar"
    }]
  }, {
    path: "/ksa_ar/failed-order",
    component: _40b74b78,
    name: "failed-order___ksa_ar"
  }, {
    path: "/ksa_ar/Home",
    component: _693c16f0,
    name: "Home___ksa_ar"
  }, {
    path: "/ksa_ar/Page",
    component: _1b970a38,
    name: "Page___ksa_ar"
  }, {
    path: "/ksa_ar/search",
    component: _252c7e1b,
    name: "search___ksa_ar"
  }, {
    path: "/ksa_ar/signin",
    component: _5b72c23e,
    name: "signin___ksa_ar"
  }, {
    path: "/ksa_ar/signup",
    component: _700918b4,
    name: "auth___ksa_ar"
  }, {
    path: "/ksa_ar/store-locations",
    component: _77ddb1be,
    name: "store___ksa_ar"
  }, {
    path: "/ksa_ar/thank-you",
    component: _e731f960,
    name: "thank-you___ksa_ar"
  }, {
    path: "/omn_ar/brands",
    component: _08cf0116,
    name: "brand___omn_ar"
  }, {
    path: "/omn_ar/cart",
    component: _eec77144,
    name: "cart___omn_ar"
  }, {
    path: "/omn_ar/checkout",
    component: _2ce358f8,
    name: "checkout___omn_ar",
    children: [{
      path: "billing",
      component: _6d37ce60,
      name: "billing___omn_ar"
    }, {
      path: "payment",
      component: _68136adb,
      name: "payment___omn_ar"
    }, {
      path: "shipping",
      component: _ba4d90ae,
      name: "shipping___omn_ar"
    }, {
      path: "shipping-and-billing",
      component: _e7aafda6,
      name: "shipping-and-billing___omn_ar"
    }, {
      path: "user-account",
      component: _07db7037,
      name: "user-account___omn_ar"
    }]
  }, {
    path: "/omn_ar/Cms",
    component: _1abd86a0,
    name: "Cms___omn_ar"
  }, {
    path: "/omn_ar/compare",
    component: _ead892fc,
    name: "compare___omn_ar"
  }, {
    path: "/omn_ar/customer",
    component: _6fb0545d,
    meta: {"titleLabel":"My Account"},
    name: "customer___omn_ar",
    children: [{
      path: "addresses-details",
      component: _ded1166a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___omn_ar"
    }, {
      path: "my-newsletter",
      component: _2819e5b9,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___omn_ar"
    }, {
      path: "my-profile",
      component: _6e223087,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___omn_ar"
    }, {
      path: "my-reviews",
      component: _e3abd16a,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___omn_ar"
    }, {
      path: "my-wishlist",
      component: _364dcac5,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___omn_ar"
    }, {
      path: "order-history",
      component: _99a633ea,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___omn_ar"
    }, {
      path: "addresses-details/create",
      component: _2f842452,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___omn_ar"
    }, {
      path: "/omn_ar/reset-password",
      component: _bf4eaff4,
      alias: "/customer/account/createPassword",
      name: "reset-password___omn_ar"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2f84dde3,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___omn_ar"
    }, {
      path: "order-history/:orderId",
      component: _19c22ff0,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___omn_ar"
    }]
  }, {
    path: "/omn_ar/failed-order",
    component: _40b74b78,
    name: "failed-order___omn_ar"
  }, {
    path: "/omn_ar/Home",
    component: _693c16f0,
    name: "Home___omn_ar"
  }, {
    path: "/omn_ar/Page",
    component: _1b970a38,
    name: "Page___omn_ar"
  }, {
    path: "/omn_ar/search",
    component: _252c7e1b,
    name: "search___omn_ar"
  }, {
    path: "/omn_ar/signin",
    component: _5b72c23e,
    name: "signin___omn_ar"
  }, {
    path: "/omn_ar/signup",
    component: _700918b4,
    name: "auth___omn_ar"
  }, {
    path: "/omn_ar/store-locations",
    component: _77ddb1be,
    name: "store___omn_ar"
  }, {
    path: "/omn_ar/thank-you",
    component: _e731f960,
    name: "thank-you___omn_ar"
  }, {
    path: "/omn_en/brands",
    component: _08cf0116,
    name: "brand___omn_en"
  }, {
    path: "/omn_en/cart",
    component: _eec77144,
    name: "cart___omn_en"
  }, {
    path: "/omn_en/checkout",
    component: _2ce358f8,
    name: "checkout___omn_en",
    children: [{
      path: "billing",
      component: _6d37ce60,
      name: "billing___omn_en"
    }, {
      path: "payment",
      component: _68136adb,
      name: "payment___omn_en"
    }, {
      path: "shipping",
      component: _ba4d90ae,
      name: "shipping___omn_en"
    }, {
      path: "shipping-and-billing",
      component: _e7aafda6,
      name: "shipping-and-billing___omn_en"
    }, {
      path: "user-account",
      component: _07db7037,
      name: "user-account___omn_en"
    }]
  }, {
    path: "/omn_en/Cms",
    component: _1abd86a0,
    name: "Cms___omn_en"
  }, {
    path: "/omn_en/compare",
    component: _ead892fc,
    name: "compare___omn_en"
  }, {
    path: "/omn_en/customer",
    component: _6fb0545d,
    meta: {"titleLabel":"My Account"},
    name: "customer___omn_en",
    children: [{
      path: "addresses-details",
      component: _ded1166a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___omn_en"
    }, {
      path: "my-newsletter",
      component: _2819e5b9,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___omn_en"
    }, {
      path: "my-profile",
      component: _6e223087,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___omn_en"
    }, {
      path: "my-reviews",
      component: _e3abd16a,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___omn_en"
    }, {
      path: "my-wishlist",
      component: _364dcac5,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___omn_en"
    }, {
      path: "order-history",
      component: _99a633ea,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___omn_en"
    }, {
      path: "addresses-details/create",
      component: _2f842452,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___omn_en"
    }, {
      path: "/omn_en/reset-password",
      component: _bf4eaff4,
      alias: "/customer/account/createPassword",
      name: "reset-password___omn_en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2f84dde3,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___omn_en"
    }, {
      path: "order-history/:orderId",
      component: _19c22ff0,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___omn_en"
    }]
  }, {
    path: "/omn_en/failed-order",
    component: _40b74b78,
    name: "failed-order___omn_en"
  }, {
    path: "/omn_en/Home",
    component: _693c16f0,
    name: "Home___omn_en"
  }, {
    path: "/omn_en/Page",
    component: _1b970a38,
    name: "Page___omn_en"
  }, {
    path: "/omn_en/search",
    component: _252c7e1b,
    name: "search___omn_en"
  }, {
    path: "/omn_en/signin",
    component: _5b72c23e,
    name: "signin___omn_en"
  }, {
    path: "/omn_en/signup",
    component: _700918b4,
    name: "auth___omn_en"
  }, {
    path: "/omn_en/store-locations",
    component: _77ddb1be,
    name: "store___omn_en"
  }, {
    path: "/omn_en/thank-you",
    component: _e731f960,
    name: "thank-you___omn_en"
  }, {
    path: "/uae_ar/brands",
    component: _08cf0116,
    name: "brand___uae_ar"
  }, {
    path: "/uae_ar/cart",
    component: _eec77144,
    name: "cart___uae_ar"
  }, {
    path: "/uae_ar/checkout",
    component: _2ce358f8,
    name: "checkout___uae_ar",
    children: [{
      path: "billing",
      component: _6d37ce60,
      name: "billing___uae_ar"
    }, {
      path: "payment",
      component: _68136adb,
      name: "payment___uae_ar"
    }, {
      path: "shipping",
      component: _ba4d90ae,
      name: "shipping___uae_ar"
    }, {
      path: "shipping-and-billing",
      component: _e7aafda6,
      name: "shipping-and-billing___uae_ar"
    }, {
      path: "user-account",
      component: _07db7037,
      name: "user-account___uae_ar"
    }]
  }, {
    path: "/uae_ar/Cms",
    component: _1abd86a0,
    name: "Cms___uae_ar"
  }, {
    path: "/uae_ar/compare",
    component: _ead892fc,
    name: "compare___uae_ar"
  }, {
    path: "/uae_ar/customer",
    component: _6fb0545d,
    meta: {"titleLabel":"My Account"},
    name: "customer___uae_ar",
    children: [{
      path: "addresses-details",
      component: _ded1166a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___uae_ar"
    }, {
      path: "my-newsletter",
      component: _2819e5b9,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___uae_ar"
    }, {
      path: "my-profile",
      component: _6e223087,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___uae_ar"
    }, {
      path: "my-reviews",
      component: _e3abd16a,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___uae_ar"
    }, {
      path: "my-wishlist",
      component: _364dcac5,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___uae_ar"
    }, {
      path: "order-history",
      component: _99a633ea,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___uae_ar"
    }, {
      path: "addresses-details/create",
      component: _2f842452,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___uae_ar"
    }, {
      path: "/uae_ar/reset-password",
      component: _bf4eaff4,
      alias: "/customer/account/createPassword",
      name: "reset-password___uae_ar"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2f84dde3,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___uae_ar"
    }, {
      path: "order-history/:orderId",
      component: _19c22ff0,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___uae_ar"
    }]
  }, {
    path: "/uae_ar/failed-order",
    component: _40b74b78,
    name: "failed-order___uae_ar"
  }, {
    path: "/uae_ar/Home",
    component: _693c16f0,
    name: "Home___uae_ar"
  }, {
    path: "/uae_ar/Page",
    component: _1b970a38,
    name: "Page___uae_ar"
  }, {
    path: "/uae_ar/search",
    component: _252c7e1b,
    name: "search___uae_ar"
  }, {
    path: "/uae_ar/signin",
    component: _5b72c23e,
    name: "signin___uae_ar"
  }, {
    path: "/uae_ar/signup",
    component: _700918b4,
    name: "auth___uae_ar"
  }, {
    path: "/uae_ar/store-locations",
    component: _77ddb1be,
    name: "store___uae_ar"
  }, {
    path: "/uae_ar/thank-you",
    component: _e731f960,
    name: "thank-you___uae_ar"
  }, {
    path: "/uae_en/brands",
    component: _08cf0116,
    name: "brand___uae_en"
  }, {
    path: "/uae_en/cart",
    component: _eec77144,
    name: "cart___uae_en"
  }, {
    path: "/uae_en/checkout",
    component: _2ce358f8,
    name: "checkout___uae_en",
    children: [{
      path: "billing",
      component: _6d37ce60,
      name: "billing___uae_en"
    }, {
      path: "payment",
      component: _68136adb,
      name: "payment___uae_en"
    }, {
      path: "shipping",
      component: _ba4d90ae,
      name: "shipping___uae_en"
    }, {
      path: "shipping-and-billing",
      component: _e7aafda6,
      name: "shipping-and-billing___uae_en"
    }, {
      path: "user-account",
      component: _07db7037,
      name: "user-account___uae_en"
    }]
  }, {
    path: "/uae_en/Cms",
    component: _1abd86a0,
    name: "Cms___uae_en"
  }, {
    path: "/uae_en/compare",
    component: _ead892fc,
    name: "compare___uae_en"
  }, {
    path: "/uae_en/customer",
    component: _6fb0545d,
    meta: {"titleLabel":"My Account"},
    name: "customer___uae_en",
    children: [{
      path: "addresses-details",
      component: _ded1166a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___uae_en"
    }, {
      path: "my-newsletter",
      component: _2819e5b9,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___uae_en"
    }, {
      path: "my-profile",
      component: _6e223087,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___uae_en"
    }, {
      path: "my-reviews",
      component: _e3abd16a,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___uae_en"
    }, {
      path: "my-wishlist",
      component: _364dcac5,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___uae_en"
    }, {
      path: "order-history",
      component: _99a633ea,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___uae_en"
    }, {
      path: "addresses-details/create",
      component: _2f842452,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___uae_en"
    }, {
      path: "/uae_en/reset-password",
      component: _bf4eaff4,
      alias: "/customer/account/createPassword",
      name: "reset-password___uae_en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2f84dde3,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___uae_en"
    }, {
      path: "order-history/:orderId",
      component: _19c22ff0,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___uae_en"
    }]
  }, {
    path: "/uae_en/failed-order",
    component: _40b74b78,
    name: "failed-order___uae_en"
  }, {
    path: "/uae_en/Home",
    component: _693c16f0,
    name: "Home___uae_en"
  }, {
    path: "/uae_en/Page",
    component: _1b970a38,
    name: "Page___uae_en"
  }, {
    path: "/uae_en/search",
    component: _252c7e1b,
    name: "search___uae_en"
  }, {
    path: "/uae_en/signin",
    component: _5b72c23e,
    name: "signin___uae_en"
  }, {
    path: "/uae_en/signup",
    component: _700918b4,
    name: "auth___uae_en"
  }, {
    path: "/uae_en/store-locations",
    component: _77ddb1be,
    name: "store___uae_en"
  }, {
    path: "/uae_en/thank-you",
    component: _e731f960,
    name: "thank-you___uae_en"
  }, {
    path: "/bhr_ar/brands/:id",
    component: _bb06e782,
    name: "brands___bhr_ar"
  }, {
    path: "/bhr_en/brands/:id",
    component: _bb06e782,
    name: "brands___bhr_en"
  }, {
    path: "/ksa_ar/brands/:id",
    component: _bb06e782,
    name: "brands___ksa_ar"
  }, {
    path: "/omn_ar/brands/:id",
    component: _bb06e782,
    name: "brands___omn_ar"
  }, {
    path: "/omn_en/brands/:id",
    component: _bb06e782,
    name: "brands___omn_en"
  }, {
    path: "/uae_ar/brands/:id",
    component: _bb06e782,
    name: "brands___uae_ar"
  }, {
    path: "/uae_en/brands/:id",
    component: _bb06e782,
    name: "brands___uae_en"
  }, {
    path: "/bhr_ar/:slug+",
    component: _1b970a38,
    name: "page___bhr_ar"
  }, {
    path: "/bhr_en/:slug+",
    component: _1b970a38,
    name: "page___bhr_en"
  }, {
    path: "/brands/:id",
    component: _bb06e782,
    name: "brands___ksa_en"
  }, {
    path: "/ksa_ar/:slug+",
    component: _1b970a38,
    name: "page___ksa_ar"
  }, {
    path: "/omn_ar/:slug+",
    component: _1b970a38,
    name: "page___omn_ar"
  }, {
    path: "/omn_en/:slug+",
    component: _1b970a38,
    name: "page___omn_en"
  }, {
    path: "/uae_ar/:slug+",
    component: _1b970a38,
    name: "page___uae_ar"
  }, {
    path: "/uae_en/:slug+",
    component: _1b970a38,
    name: "page___uae_en"
  }, {
    path: "/",
    component: _693c16f0,
    name: "home___ksa_en"
  }, {
    path: "/:slug+",
    component: _1b970a38,
    name: "page___ksa_en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
