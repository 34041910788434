export const createCompareListMutation = `

mutation CreateCompareList($input: CreateCompareListInput!) {
    createCompareList(input: $input) {
        uid
        item_count
        attributes {
            code
            label
        }
        items {
            uid
            product {
                id
                sku
                name
                uid
                stock_status
                description {
                    html
                }
                
                thumbnail {
                    url
                    position
                    disabled
                    label
                }
                url_key
                url_rewrites {
                url
                }
                 price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
            }
        }
    }
}

`;

export const addToCompareList = `

mutation addProductsToCompareList($input: AddProductsToCompareListInput!) {
    addProductsToCompareList(input: $input) {
        uid
        item_count
        attributes {
            code
            label
        }
        items {
            uid
            product {
                id
                sku
                name
                uid
                stock_status
                description {
                    html
                }
                
                thumbnail {
                    url
                    position
                    disabled
                    label
                }
                url_key
                url_rewrites {
                url
                }
                 price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
            }
        }
    }
}
`

export const getCompareListQuery = `

query GetCompareList($uid: ID!) {
    compareList(uid: $uid) {
        uid
        item_count
        attributes {
            code
            label
        }
        items {
            uid
            product {
                id
                sku
                name
                uid
                stock_status
                description {
                    html
                }
                
                thumbnail {
                    url
                    position
                    disabled
                    label
                }
                url_key
                url_rewrites {
                url
                }
                price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
            }
        }
    }
}

`;


export const deleteCompareProductMutation = `

mutation RemoveProductsFromCompareList($input: RemoveProductsFromCompareListInput!) {
    removeProductsFromCompareList(input: $input) {
        uid
        item_count
        attributes {
            code
            label
        }
        items {
            uid
            product {
                id
                sku
                name
                uid
                stock_status
                description {
                    html
                }
                
                thumbnail {
                    url
                    position
                    disabled
                    label
                }
                url_key
                url_rewrites {
                url
                }
                price_range {
                    minimum_price {
                        regular_price {
                            value
                            currency
                        }
                    }
                }
            }
        }
    }
}

`