





























































































































































































































































































import {
  SfFooter, SfList, SfImage, SfMenuItem,
} from '@storefront-ui/vue';
import { computed, defineComponent,ref, useContext, useFetch, watch } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { useConfig, useContent, useUiNotification } from '~/composables';
import { footer_static_blocks } from '~/utils/HomePageIdentifiers';

export default defineComponent({
  components: {
    SfFooter,
    SfList,
    SfImage,
    SfMenuItem,
  },
  setup() {
    const email = ref("");
    const error = ref("");
    const subscribeLoader = ref(false);
    const {app} = useContext();
    const { send: sendNotification } = useUiNotification();
    const { config } = useConfig();
    const footer_blocks = ref([]);
    const {loadBlocks} = useContent();
    const logo_src = computed(() => {
        return process.env.VSF_MAGENTO_BASE_URL + '/media/logo/' + (config.value as any)?.header_logo_src
      })
    useFetch( async () => {
    const all_blocks =  await loadBlocks({
      identifiers:footer_static_blocks(app.i18n.locale),
    });
    footer_blocks.value = all_blocks.map((items) => {
     return {
       ...items,
      content:items?.content || ''
     }
    })
    console.log("all_blocks ----->",all_blocks)
    })

    // Function to validate email
    const validateEmail = (email) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };
    watch(email, () => {
      if (email.value.trim() && error.value == 'Email is required') {
        error.value = "";
      }
    })

    const MutationQuery = `
  mutation subscribeEmailToNewsletter($email: String!) {
    subscribeEmailToNewsletter(
      email: $email
    ) {
      status
    }
  }
`;

    // Subscribe function
    const subscribe = async (e) => {
      e.preventDefault();
      if (!email.value.trim()) {
        error.value = "Email is required";
        return;
      }
      if (!validateEmail(email.value.trim())) {
        error.value = "Please enter a valid email address";
        return;
      }
      subscribeLoader.value = true
      error.value = "";
      

      const {data,errors} = await app.$vsf.$magento.api.customQuery({
        query: MutationQuery,
        queryVariables: {
          email: email.value
        },
        customHeaders:{
          'store':app.i18n.locale
        }
      });

      if(errors){
        subscribeLoader.value = false
        
        sendNotification({
          icon: 'error',
          id: Symbol(`subscription-error`),
          message: errors[0].message,
          persist: false,
          title: 'error',
          type: 'danger',
        })
      } else {
        subscribeLoader.value = false
        sendNotification({
          icon: 'success',
          id: Symbol(`subscription-message`),
          message: 'Successfully subscribed !!',
          persist: false,
          title: 'success',
          type: 'success',
        });
        email.value = "";
      }
     
    };
    return {
      addBasePath,
      subscribe,
      email,
      error,
      subscribeLoader,
      config:(config as any),
      footer_blocks,
      logo_src
    };
  },
  data() {
    return {
      aboutUs: ['Who we are', 'Quality in the details', 'Customer Reviews'],
      departments: ['Women fashion', 'Men fashion', 'Kidswear', 'Home'],
      help: ['Customer service', 'Size guide', 'Contact us'],
      paymentsDelivery: ['Purchase terms', 'Guarantee'],
      social: ['facebook', 'pinterest', 'google', 'twitter', 'youtube'],
      isMobile: false,
      desktopMin: 1024,
      
    };
  },
});
