export const getConfigQuery = `
   query storeConfig {
  storeConfig {
    allow_guests_to_write_product_reviews
    allow_items
    allow_order
    base_currency_code
    base_media_url
    catalog_default_sort_by
    category_fixed_product_tax_display_setting
    cms_home_page
    cms_no_cookies
    cms_no_route
    configurable_thumbnail_source
    copyright
    default_description
    default_display_currency_code
    default_keywords
    default_title
    grid_per_page
    grid_per_page_values
    head_shortcut_icon
    header_logo_src
    is_default_store
    is_default_store_group
    list_mode
    list_per_page
    list_per_page_values
    locale
    logo_alt
    logo_height
    logo_width
    magento_wishlist_general_is_enabled
    minimum_password_length
    no_route
    product_fixed_product_tax_display_setting
    product_reviews_enabled
    required_character_classes_number
    root_category_uid
    sales_fixed_product_tax_display_setting
    store_code
    store_group_code
    store_group_name
    store_name
    store_sort_order
    timezone
    title_prefix
    title_separator
    title_suffix
    use_store_in_url
    website_code
    website_name
    weight_unit
    welcome

    # Additional fields from your query
    base_url
    base_link_url
    base_static_url
    base_media_url
    secure_base_url
    secure_base_link_url
    secure_base_static_url
    secure_base_media_url
    front
    cms_home_page
    no_route
    cms_no_route
    cms_no_cookies
    show_cms_breadcrumbs
    recaptcha_frontend_type_recaptcha_v3_public_key
    recaptcha_frontend_type_recaptcha_v3_private_key
    general_store_information_phone
    web_cookie_cookie_restriction
    tabby_tabby_api_use_redirect
    tabby_tabby_api_public_key
    tabby_tabby_api_secret_key
    tabby_tabby_api_local_currency
    tabby_tabby_api_abandoned_timeout
    tabby_tabby_api_order_history_use_phone
    tabby_tabby_api_debug
    tabby_tabby_api_mark_complete
    tabby_tabby_api_authorized_status
    tabby_tabby_api_capture_on
    tabby_tabby_api_create_pending_invoice
    tabby_tabby_api_product_promotions
    tabby_tabby_api_cart_promotions
    tabby_tabby_api_promo_theme
    tabby_tabby_api_promo_limit
    tabby_tabby_api_hide_methods
    tabby_tabby_api_show_logo
    tabby_tabby_api_logo_color
    tabby_tabby_api_disable_for_sku
    tabby_tabby_api_allowspecific
    tabby_tabby_api_specificcountry
    payment_tabby_checkout_active
    payment_tabby_checkout_title
    payment_tabby_checkout_sort_order
    payment_tabby_installments_active
    payment_tabby_installments_title
    payment_tabby_installments_sort_order
    payment_telr_telrpayments_active
    payment_telr_telrpayments_allowspecific
    payment_telr_telrpayments_title
    payment_telr_telrpayments_sandbox
    payment_telr_telrpayments_ivp_framed
    payment_telr_telrpayments_telr_lang
    payment_telr_telrpayments_telr_txn_type
    payment_telr_telrpayments_store_id
    payment_telr_telrpayments_auth_key
    
    payment_telr_telrpayments_order_status
    payment_telr_telrpayments_transaction_desc
    payment_telr_telrpayments_specificcountry
    payment_telr_telrpayments_min_order_total
    payment_telr_telrpayments_max_order_total
    payment_tamara_checkout_api_environment
    payment_tamara_checkout_api_public_key
    payment_tamara_checkout_api_specificcountry
    payment_tamara_checkout_api_block_web_view
    payment_tamara_checkout_api_merchant_success_url
    payment_tamara_checkout_api_merchant_cancel_url
    payment_tamara_checkout_api_merchant_failure_url
    payment_tamara_checkout_api_use_magento_success_page
    payment_tamara_checkout_api_enable_pdp_widget
    payment_tamara_checkout_api_exclude_product_ids
    payment_tamara_pay_by_instalments_4_active
    payment_tamara_pay_by_instalments_4_payment_action
    bqconfigsection_configs_config_whatsapp
    bqconfigsection_configs_config_whatsapp_text
    bqconfigsection_configs_config_phone_text
    bqconfigsection_configs_facebook_link
    bqconfigsection_configs_twitter_link
    bqconfigsection_configs_linken_link
    bqconfigsection_configs_youtube_link
    bqconfigsection_configs_snapchat_link
    bqconfigsection_configs_instagram_link

    header_logo_src
    default_title
    logo_alt
    logo_width
    logo_height
    head_includes
    default_keywords
    default_description
    default_robots

   bqconfigsection_login_config_text
    bqconfigsection_login_config_stitle
    bqconfigsection_login_config_intro
    bqconfigsection_login_config_url 
    bqconfigsection_login_config_image

    bqconfigsection_signup_config_text
    bqconfigsection_signup_config_stitle
    bqconfigsection_signup_config_intro 
    bqconfigsection_signup_config_url 
    bqconfigsection_signup_config_image  

    bqconfigsection_offer_config_offer_text   
    bqconfigsection_offer_config_offer_url   
    bqconfigsection_offer_config_offer1_text   
    bqconfigsection_offer_config_offer1_url 

    bqconfigsection_newsletter_config_newsletter_title 
    bqconfigsection_newsletter_config_newsletter_description 
    bqconfigsection_homepage_config 
    bqconfigsection_notice_config_title 
    bqconfigsection_notice_config_image 
    bqconfigsection_newsletter_config_news_text
    bqconfigsection_newsletter_config_enable
    bqconfigsection_newsletter_config_image
  }
}

`;