/* eslint-disable consistent-return */
import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useUiNotification, type ComposableFunctionArgs } from '~/composables';
import { addReviewCommand } from './commands/addReviewCommand';
import { loadCustomerReviewsCommand } from './commands/loadCustomerReviewsCommand';
import { loadReviewMetadataCommand } from './commands/loadReviewMetadataCommand';
import { searchReviewsCommand } from './commands/searchReviewsCommand';
import type {
  UseReviewErrors,
  UseReviewInterface,
  UseReviewSearchParams,
  UseReviewAddReviewParams,
} from './useReview';

/**
 * Allows loading and adding product reviews.
 *
 * See the {@link UseReviewInterface} for a list of methods and values available in this composable.
 */
export function useReview(): UseReviewInterface {
  const loading = ref(false);
  const error = ref<UseReviewErrors>({
    search: null,
    addReview: null,
    loadReviewMetadata: null,
    loadCustomerReviews: null,
  });
  const { $recaptcha, $config } = useContext() as any;
  const { app } = useContext();
  const context = app.$vsf;
  const { send: sendNotification } = useUiNotification();

  const search = async (searchParams: UseReviewSearchParams) => {
    Logger.debug('useReview/search', searchParams);

    searchParams.customHeaders = {
      'store':app.i18n.locale
    }
    
    try {
      loading.value = true;
      error.value.search = null;
      return await searchReviewsCommand.execute(context, searchParams);
    } catch (err) {
      error.value.search = err;
      Logger.error('useReview/search', err);
    } finally {
      loading.value = false;
    }
  };

  const loadCustomerReviews = async () => {
    Logger.debug('useReview/loadCustomerReviews');
    
    try {
      loading.value = true;
      error.value.loadCustomerReviews = null;
      return await loadCustomerReviewsCommand.execute(context);
    } catch (err) {
      error.value.loadCustomerReviews = err;
      Logger.error('useReview/loadCustomerReviews', err);
    } finally {
      loading.value = false;
    }
  };

  const loadReviewMetadata = async (params?: ComposableFunctionArgs<{}>) => {
    Logger.debug('useReview/loadReviewMetadata');

    try {
      loading.value = true;
      error.value.loadReviewMetadata = null;
      return await loadReviewMetadataCommand.execute(context, params);
    } catch (err) {
      error.value.loadReviewMetadata = err;
      Logger.error('useReview/loadReviewMetadata', err);
    } finally {
      loading.value = false;
    }
  
  };





  const getRecaptchaInfo = async (
    isRecaptchaOn: boolean
  ): Promise<{ token: string | null; cleanup: () => void }> => {
    if (isRecaptchaOn) {
      
  
      // Wait for initialization
      await $recaptcha.init();
  
      // Execute reCAPTCHA
      const token = await $recaptcha.execute(); // Execute reCAPTCHA to get a token
  
      return {
        token,
        cleanup: () => {
          $recaptcha.reset();
        },
      };
    }
  
    return { token: null, cleanup: () => {} };
  };






  const addReview = async (params: UseReviewAddReviewParams) => {
    loading.value = true;
    Logger.debug('useReview/addReview', params);
    const isRecaptchaEnabled = ref<boolean>(
      typeof $recaptcha !== "undefined" && Boolean($config.isRecaptcha)
    );
    const { token, cleanup } = await getRecaptchaInfo(
      isRecaptchaEnabled.value
    );
    

    params.customHeaders = {
      'store':app.i18n.locale
    }

    if(token){
      params.customHeaders['X-ReCaptcha'] = token
    }

    try {
      
      error.value.addReview = null;
      const response = await addReviewCommand.execute(context, params);
      if(response.errors) {
        sendNotification({
          id: Symbol('registration_confirmation'),
          message: app.i18n.t(response.errors[0].message) as string,
          persist: false,
          title: 'review',
          type: 'danger',
          icon: 'error',
        });
        return response
      } 
      sendNotification({
        id: Symbol('registration_confirmation'),
        message: app.i18n.t('Thanks for your review !!') as string,
        persist: false,
        title: 'review',
        type: 'success',
        icon: 'check',
      });
      return response;
    } catch (err) {
      error.value.addReview = err;
      console.log("Error while submitting review !! ---->", err)
      Logger.error('useReview/addReview', err);
    } finally {
      loading.value = false;
      
    }
  };

  return {
    search,
    addReview,
    loadReviewMetadata,
    loadCustomerReviews,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useReview;
export * from './useReview';
