


























































import { computed, defineComponent, onMounted } from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';
import { useUiNotification } from '~/composables';

export default defineComponent({
  name: 'NotificationBar',
  components: {
    SvgImage,
  },
  setup() {
    const { notifications } = useUiNotification();

    // Mapping notification types to background and icon classes
    const bgClass = (type: string) => {
      switch (type) {
        case 'success':
          return 'bg-green-200 dark:bg-gray-800 text-gray-500 dark:text-gray-400';
        case 'danger':
          return 'bg-red-200 dark:bg-gray-800 text-gray-500 dark:text-gray-400';
        case 'warning':
          return 'bg-yellow-200 dark:bg-gray-800 text-gray-500 dark:text-gray-400';
        default:
          return 'bg-gray-200 dark:bg-gray-800 text-gray-500 dark:text-gray-400';
      }
    };

    const iconBgClass = (type: string) => {
      switch (type) {
        case 'success':
          return 'bg-green-100 dark:bg-green-800 text-green-500 dark:text-green-200';
        case 'danger':
          return 'bg-red-100 dark:bg-red-800 text-red-500 dark:text-red-200';
        case 'warning':
          return 'bg-yellow-100 dark:bg-yellow-800 text-yellow-500 dark:text-yellow-200';
        default:
          return 'bg-gray-100 dark:bg-gray-800 text-gray-500 dark:text-gray-200';
      }
    };

    

    return {
      notifications,
      bgClass,
      iconBgClass,
    };
  },
});
