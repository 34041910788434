import { CustomQuery, CustomHeaders, UseContextReturn } from '~/types/core';
import { CustomerAddressInput } from '~/modules/GraphQL/types';

export const updateCustomerAddressCommand = {
  execute: async (context: UseContextReturn, params: {
    id: number;
    input: CustomerAddressInput;
  }, customQuery: CustomQuery, customHeaders: CustomHeaders) => {
      customHeaders = {
      'store':context.app.i18n.locale,
    }
    const { data } = await (context.app.$vsf.$magento.api as any).updateCustomerAddress(params, customQuery, customHeaders);

    return data?.updateCustomerAddress ?? {};
  },
};
