import {
  ref,
  readonly,
  computed,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import mask from '~/composables/utils/mask';
import { Logger } from '~/helpers/logger';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useCart } from '~/modules/checkout/composables/useCart';
import { generateUserData } from '~/modules/customer/helpers/generateUserData';
import { useUiNotification } from '~/composables/useUiNotification';
import type { Customer } from '~/modules/GraphQL/types';
import type {
  UseUserInterface,
  UseUserErrors,
  UseUserLoadParams,
  UseUserLoginParams,
  UseUserLogoutParams,
  UseUserRegisterParams,
  UseUserUpdateUserParams,
  UseUserChangePasswordParams,
} from './useUser';

/**
 * Allows loading and manipulating data of the current user.
 *
 * See the {@link UseUserInterface} for a list of methods and values available in this composable.
 */
export function useUser(): UseUserInterface {
  const customerStore = useCustomerStore();
  // @ts-ignore
  const { app, $recaptcha } = useContext();
  const { setCart } = useCart();
  const { send: sendNotification } = useUiNotification();
  const loading: Ref<boolean> = ref(false);
  const change_loader: Ref<boolean> = ref(false);
  const router = useRouter();
  const reset_password = ref(false)

  const errorsFactory = () : UseUserErrors => ({
    updateUser: null,
    register: null,
    login: null,
    logout: null,
    changePassword: null,
    load: null,
  });
  const error: Ref = ref(errorsFactory());

  const signup_data = ref<any>(null)

  const setUser = (newUser: Customer) => {
    customerStore.user = newUser;
    Logger.debug('useUserFactory.setUser', newUser);
  };

  const resetErrorValue = () => {
    error.value = errorsFactory();
  };

  const updateCustomerEmail = async (credentials: { email: string, password: string }): Promise<void> => {
    const { errors } = await app.context.$vsf.$magento.api.updateCustomerEmail(credentials);

    if (errors) {
      throw errors.map((e) => e.message).join(',');
    }
  };

  // eslint-disable-next-line consistent-return
  const updateUser = async ({ user: providedUser, customQuery, customHeaders }: UseUserUpdateUserParams) => {
    Logger.debug('[Magento] Update user information', { providedUser, customQuery, customHeaders });
    resetErrorValue();
    customHeaders = {
      'store':app.i18n.locale,
    }
    try {
      loading.value = true;
      const { email: oldEmail } = customerStore.user;
      const { email, password, ...updateData } = providedUser;

      const userData = generateUserData(updateData);

      if (email && email !== oldEmail) {
        await updateCustomerEmail({
          email,
          password,
        });
      }

      const { data, errors } = await app.context.$vsf.$magento.api.updateCustomer(userData, customQuery, customHeaders);
      Logger.debug('[Result]:', { data });

      if (errors) {
        const allErrorMessages = errors.map((e) => e.message).join(',');
        Logger.error(allErrorMessages);
        error.value.updateUser = allErrorMessages;
      }

      customerStore.user = data?.updateCustomerV2?.customer || {};
      error.value.updateUser = null;
    } catch (err) {
      error.value.updateUser = err;
      Logger.error('useUser/updateUser', err);
    } finally {
      loading.value = false;
    }
  };

  const logout = async ({ customQuery = {}, customHeaders = {} }: UseUserLogoutParams = {}) => {
    Logger.debug('[Magento] useUserFactory.logout');
    resetErrorValue();

    try {
      const apiState = app.context.$vsf.$magento.config.state;

      await app.context.$vsf.$magento.api.revokeCustomerToken(customQuery, customHeaders);

      apiState.removeCustomerToken();
      apiState.removeCartId();
      setCart(null);
      customerStore.setIsLoggedIn(false);
      error.value.logout = null;
      customerStore.user = null;
    } catch (err) {
      error.value.logout = err;
      Logger.error('useUser/logout', err);
    }
  };

  const load = async ({ customQuery = {}, customHeaders = {} }: UseUserLoadParams = {}) => {
    Logger.debug('[Magento] useUser.load');
    resetErrorValue();
    customHeaders = {
      'store':app.i18n.locale,
    }
    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;

      if (!apiState.getCustomerToken()) {
        return null;
      }
      try {
        const { data } = await app.context.$vsf.$magento.api.customer(customQuery, customHeaders);

        Logger.debug('[Result]:', { data });

        customerStore.user = data?.customer ?? {};
      } catch {
        // eslint-disable-next-line no-void
        // @ts-ignore
        await logout();
      }
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useUser/load', err);
    } finally {
      loading.value = false;
    }

    return customerStore.user;
  };

  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const login = async ({ user: providedUser, customQuery, customHeaders }: UseUserLoginParams) : Promise<void> => {
    Logger.debug('[Magento] useUser.login', providedUser);
    resetErrorValue();
    customHeaders = {
      'store':app.i18n.locale,
      'X-ReCaptcha':providedUser.recaptchaToken
    }
    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;

      // const { data, errors } = await app.$vsf.$magento.api.generateCustomerToken(
      //   {
      //     email: providedUser.email,
      //     password: providedUser.password,
      //     recaptchaToken: providedUser.recaptchaToken,
      //   },
      //   customQuery || {},
      //   customHeaders || {
          
      //   },
      // );

      const {data,errors} = await app.$vsf.$magento.api.customMutation({
        mutation: `
          mutation generateCustomerToken($email: String!, $password: String!) {
            generateCustomerToken(email: $email, password: $password) {
              token
            }
          }
        `,
        mutationVariables:{
          email: providedUser.email,
          password: providedUser.password
        },
        customHeaders:{
          'store':app.i18n.locale,
          'X-ReCaptcha':providedUser.recaptchaToken
        }
      }) as any
      Logger.debug('[Result]:', { data });

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) => sendNotification({
          icon: 'error',
          id: Symbol(`registration_error-${i}`),
          message: registerError.message,
          persist: false,
          title: 'Registration error',
          type: 'danger',
        }));
        
        router.push({path: app.i18n.locale !== app.i18n.defaultLocale ? '/' + app.i18n.locale + '/signin' : '/signin'});
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors);
      }

      customerStore.setIsLoggedIn(true);
      apiState.setCustomerToken(data.generateCustomerToken.token);

      // merge existing cart with customer cart
      // todo: move this logic to separate method
      const currentCartId = apiState.getCartId();
      const cart = await app.context.$vsf.$magento.api.customerCart();
      const newCartId = cart.data.customerCart.id;

      try {
        if (newCartId && currentCartId && currentCartId !== newCartId) {
          const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts(
            {
              sourceCartId: currentCartId,
              destinationCartId: newCartId,
            },
          );

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCart(dataMergeCart.mergeCarts);

          apiState.setCartId(dataMergeCart.mergeCarts.id);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCart(cart.data.customerCart);
        }
      } catch {
        // Workaround for Magento 2.4.4 mergeCarts mutation error related with Bundle products
        // It can be removed when Magento 2.4.5 will be release
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCart(cart.data.customerCart);
      }
      error.value.login = null;
    } catch (err) {
      error.value.login = err;
      Logger.error('useUser/login', err);
    } finally {
      loading.value = false;
    }
  };

  // eslint-disable-next-line consistent-return
  const register = async ({ user: providedUser, customQuery, customHeaders }: UseUserRegisterParams) : Promise<void> => {
    Logger.debug('[Magento] useUser.register', providedUser);
    resetErrorValue();
     customHeaders = {
      'store':app.i18n.locale,
    }
    try {
      loading.value = true;

      const {
        email,
        password,
        recaptchaToken,
        ...baseData
      } = generateUserData(providedUser);

      // const { data, errors } = await app.$vsf.$magento.api.createCustomer(
      //   {
      //     email,
      //     password,
      //     recaptchaToken,
      //     ...baseData,
      //   },
      //   customQuery || {},
      //   customHeaders || {},
      // );


      const {data, errors} = await app.$vsf.$magento.api.customMutation({
        mutation:`
         mutation createCustomer($input: CustomerCreateInput!) {
    createCustomerV2(input: $input) {
      customer {
        date_of_birth
        default_billing
        default_shipping
        email
        firstname
        is_subscribed
        lastname
        middlename
        prefix
        suffix
        taxvat
        addresses {
          city
          country_code
          default_billing
          default_shipping
          extension_attributes {
            attribute_code
            value
          }
          firstname
          id
          lastname
          postcode
          prefix
          region {
            region_code
            region_id
            region
          }
          street
          suffix
          telephone
          vat_id
        }
      }
    }
  }
        `,
        mutationVariables:{
          input:{
            email,
            password,
          ...baseData,
          }
        },
        customHeaders:{
          'store':app.i18n.locale,
          'X-ReCaptcha':providedUser.recaptchaToken
        }
      })

      Logger.debug('[Result]:', { data });
      signup_data.value = errors;
      if (errors.length > 0) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) => sendNotification({
          icon: 'error',
          id: Symbol(`registration_error-${i}`),
          message: registerError.message,
          persist: false,
          title: 'Registration error',
          type: 'danger',
        }));

        return;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        // throw new Error(joinedErrors);
        // return;
      } else {
        signup_data.value = null;
        let loginRecaptchaToken = '';
        if ($recaptcha && providedUser.recaptchaToken) {
          loginRecaptchaToken = await $recaptcha.execute();;
        }
        await login({ user: { email, password, recaptchaToken: loginRecaptchaToken }, customQuery: {} });

        return await new Promise((resolve) => {
          sendNotification({
            id: Symbol('registration_confirmation'),
            message: app.i18n.t('Register Success! You must confirm your account. Please check your email for the confirmation link.') as string,
            persist: false,
            title: 'Registration confirmation',
            type: 'success',
            icon: 'check',
          });
          router.push({path: app.i18n.locale !== app.i18n.defaultLocale ?( '/' + app.i18n.locale + '/') : '/'});
          resolve();
        });
      }
      
      // error.value.register = null;
      

      // eslint-disable-next-line @typescript-eslint/naming-convention
      // const { customer: { customer_create_account_confirm } } = app.context.$vsf.$magento.config;

      // if (customer_create_account_confirm) {
      //   return await new Promise((resolve) => {
      //     sendNotification({
      //       id: Symbol('registration_confirmation'),
      //       message: app.i18n.t('Register Success! You must confirm your account. Please check your email for the confirmation link.') as string,
      //       persist: false,
      //       title: 'Registration confirmation',
      //       type: 'success',
      //       icon: 'check',
      //     });
      //     router.push({path: 'signin'});
      //     resolve();
      //   });
      // }
    } catch (err) {
      error.value.register = err;
      Logger.error('useUser/register', err);
    } finally {
      loading.value = false;
    }
  };

  // eslint-disable-next-line consistent-return
  const changePassword = async (params: UseUserChangePasswordParams) => {
    Logger.debug('[Magento] useUser.changePassword', { currentPassword: mask(params.current), newPassword: mask(params.new) });
    resetErrorValue();

    try {
      change_loader.value = true;
     const customHeaders = {
        'store':app.i18n.locale,
        ...params?.customHeaders
      }
      const { data, errors } = await app.context.$vsf.$magento.api.changeCustomerPassword({
        currentUser: customerStore.user,
        currentPassword: params.current,
        newPassword: params.new,
      }, params.customQuery, customHeaders);

      let joinedErrors = null;

      if (errors) {
        joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
      }

      Logger.debug('[Result] ', { data });

      customerStore.user = data?.changeCustomerPassword;
      error.value.changePassword = joinedErrors;
    } catch (err) {
      error.value.changePassword = err;
      Logger.error('useUser/changePassword', err);
    } finally {
      change_loader.value = false;
      reset_password.value = false;
    }
  };

  return {
    setUser,
    updateUser,
    register,
    login,
    logout,
    changePassword,
    load,
    loading: readonly(loading),
    change_loader:readonly(change_loader),
    error: readonly(error),
    user: computed(() => customerStore.user),
    isAuthenticated: computed(() => customerStore.isLoggedIn),
    signup_data: computed(() => signup_data.value),
    reset_password
  };
}

export default useUser;
export * from './useUser';
