export default `{
   categories(
      filters: {
        parent_id: {in: ["2"]} 
      }
      pageSize:100
      currentPage: 1
    ) {
      total_count
      items {
        uid
        level
        name
        path
        include_in_menu,
        position
        image
        banner_image
        
        description
        cms_block {
          identifier
          content
          title
        }
        breadcrumbs {
          category_id
          category_name
          category_level
          category_url_key
          category_url_path
        }
        children_count
        url_path
        url_suffix
        children {
          uid
          level
          name
          position
          include_in_menu
          path
          image
          description
          children_count
           url_path
           url_suffix
           breadcrumbs {
          category_id
          category_name
          category_level
          category_url_key
          category_url_path
        }
          children {
            uid
            level
            name
            image
            position
            include_in_menu,
            path
            url_path
            url_suffix
            description
            breadcrumbs {
          category_id
          category_name
          category_level
          category_url_key
          category_url_path
        }
          }
        }
      } 
    }
  }`