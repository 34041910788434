


























































































































































































import { SfOverlay, SfHeader, SfButton, SfBadge } from "@storefront-ui/vue";
import { useCategory } from "~/modules/catalog/category/composables/useCategory";
import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
  watch,
} from "@nuxtjs/composition-api";
import HeaderNavigation from "~/components/Header/Navigation/HeaderNavigation.vue";
import {
  useCartView,
  useProduct,
  useUiHelpers,
  useUiState,
} from "~/composables";
import { useCart } from "~/modules/checkout/composables/useCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useUser } from "~/modules/customer/composables/useUser";
import { useWishlistStore } from "~/modules/wishlist/store/wishlistStore";
import type {
  CategoryTree,
  ProductInterface,
  WishlistItemInterface,
} from "~/modules/GraphQL/types";
import HeaderLogo from "~/components/HeaderLogo.vue";
import SvgImage from "~/components/General/SvgImage.vue";
import { useTopBar } from "./TopBar/useTopBar";
import CategoryDropdownComponent from "~/components/dropdowncomponent/CategoryDropdownComponent.vue";
import SignInModal from "./Modal/SignInModal.vue";
import { availableStoreCountries, countryCodeMap, determineLanguage, determineStore, languageSwitcher, replacePartInURL } from "~/utils/HomePageIdentifiers";

export default defineComponent({
  components: {
    HeaderNavigation,
    CategoryDropdownComponent,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    SignInModal,
    CurrencySelector: () => import("~/components/CurrencySelector.vue"),
    StoreSwitcher: () => import("~/components/StoreSwitcher.vue"),
    SearchBar: () => import("~/components/Header/SearchBar/SearchBar.vue"),
    SearchResults: () =>
      import(
        /* webpackPrefetch: true */ "~/components/Header/SearchBar/SearchResults.vue"
      ),
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { getProductList, getProductPath } = useProduct();

    const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal } =
      useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const {
      loadItemsCount: loadWishlistItemsCount,
      loading: wishloader,
      removeItem,
      load: loadWishlist,
    } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } =
      useCategory();
    const currentCategory = ref<CategoryTree | null>(null);
    const shoppingCart = ref(false);
    const wishCart = ref(false);
    const compareCart = ref(false);
    const searchTerm = ref("");
    const searchInput = ref(false);
    const searchItemsDropdown = ref(false);
    const searchResults = ref<any>(null);
    const search_loader = ref(false);
    const mobile_menu = ref(false);
  const searchTotalCount = ref(0);

    const selectedLanguage = ref({ name: 'عربي',code:'ar' });
    const dropdownOpen = ref(false);
    const selectedCountry = ref({
      name: "Saudi Arabia",
      flag: '',
    });
    const filteredCountries = computed(() => {
      return availableStoreCountries.filter(
        (country) => country.name !== selectedCountry.value.name
      );
    });

    const cartView = useCartView();

    const isModalVisible = ref(false);


    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();
    const categoryDropdown = ref(false);
    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(
      () => wishlistStore.wishlist?.items_count ?? 0
    );

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);

    const wishlistProducts = computed<WishlistItemInterface[]>(
      () => wishlistStore.wishlist.items_v2?.items ?? []
    );

    watch(wishlistProducts, () => {
    });

    watch(searchTerm, async (newText) => {
      if (searchTerm.value.trim().length > 0) {
        searchItemsDropdown.value = true;
        search_loader.value = true;
        const data = await getProductList({ search: newText });
        searchResults.value = data.items;
      searchTotalCount.value = data.total_count ?? 0;

        if (data.items) {
          search_loader.value = false;
        }
      } else {
        searchItemsDropdown.value = false;
        searchResults.value = null;
      }
    });

    const accountIcon = computed(() =>
      isAuthenticated.value ? "profile_fill" : "profile"
    );
    const categoryTree = ref<CategoryTree[]>([]);

    const handleAccountClick = async () => {
      try {
        if (isAuthenticated.value) {
          // If the user is authenticated, navigate to the profile page
          await router.push(app.localeRoute({ name: "customer-my-profile" }));
        } else {
          await router.push(app.localeRoute({ name: "signin" }));
        }
      } catch (error) {
        console.error("Error during navigation:", error);
      }
    };

    const setCurrentCategory = (category: CategoryTree | null) => {
      currentCategory.value = category;
    };
    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value
        .filter((category) => category.include_in_menu)
        .slice(0, 4);
    });

    onMounted(async () => {
      await loadWishlist({ searchParams: { pageSize: 100 } });
    });

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
    });
    onMounted(() => {
      selectedLanguage.value = determineLanguage(app.i18n.locale);
      selectedCountry.value = determineStore(app.i18n.locale);

    });
    const selectCountry = (country) => {
      selectedCountry.value = country;
      dropdownOpen.value = false;
      const currentURL = window.location.href;

      const languageCode =
        selectedLanguage.value.name === 'إنجليزي' ? "Arabic" : "English";
      const routeCode = countryCodeMap[country.name][languageCode];

      if (routeCode) {
        const route = replacePartInURL(currentURL, app.i18n.locale, routeCode);
        window.location.href = route;
      }
    };
    // Track mouse movement to detect if it's moving up or sideways
    const trackMouseEnter = (item) => {
      categoryDropdown.value = true;
      setCurrentCategory(item);
    };

    const goToCart = async () => {
      shoppingCart.value = false;
      await router.push(app.localeRoute({ name: "cart" }));
    };

    const proceedToCheckout = async () => {
      shoppingCart.value = false;
      cartView.goToCheckout();
    };

    const updateShoppingCart = () => {
      if (shoppingCart.value) {
        shoppingCart.value = false;
      } else {
        shoppingCart.value = true;
        wishCart.value = false;
        compareCart.value = false;
      }
    };
    const updateWishCart = () => {
      if (wishCart.value) {
        wishCart.value = false;
      } else {
        wishCart.value = true;
        shoppingCart.value = false;
        compareCart.value = false;
      }
    };
    const updateCompareCart = () => {
      if (compareCart.value) {
        compareCart.value = false;
      } else {
        compareCart.value = true;
        wishCart.value = false;
        shoppingCart.value = false;
      }
    };

    const updateSearch = () => {
      searchInput.value = true;
      wishCart.value = false;
      shoppingCart.value = false;
      compareCart.value = false;
    };

    const redirectToWishList = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      router.push(app.localeRoute({ name: "customer-my-wishlist" }));
      wishCart.value = false;
    };
    const searchProductHandler = (product: any) => {
      const url = getProductPath(product);
      router.push(app.localeRoute(url));
      searchItemsDropdown.value = false;
      searchInput.value = false;
      wishCart.value = false;
      shoppingCart.value = false;
      compareCart.value = false;
      searchTerm.value = "";
    };
    const selectLanguage = (lng) => {
      selectedLanguage.value = {
        name:lng,
        code : lng === 'إنجليزي' ? "en" : "ar",
      };
      const route = languageSwitcher(app.i18n.locale);
      const currentURL = window.location.href;
      const newURL = currentURL.replace(/([a-z]{3}_[a-z]{2})/, route);
      window.history.replaceState(null, null, newURL);
      window.location.reload();
    }
    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value;
    };
    const redirectToSearch = () => {
    router.push(app.localeRoute({ path: "/search" + "?term=" + searchTerm.value }));
    searchInput.value = false;
    searchTerm.value = "";
  }
    return {
      accountIcon,
      searchItemsDropdown,
      searchProductHandler,
      redirectToSearch,
      redirectToWishList,
      updateCompareCart,
      searchResults,
      compareCart,
      updateWishCart,
      updateShoppingCart,
      wishCart,
      goToCart,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      handleAccountClick,
      toggleLoginModal,
      isAuthenticated,
      search_loader,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
      searchTotalCount,
      categoryDropdown,
      trackMouseEnter,
      currentCategory,
      isModalVisible,
      shoppingCart,
      ...cartView,
      proceedToCheckout,
      wishlistProducts,
      removeItem,
      wishloader,
      updateSearch,
      searchInput,
      searchTerm,
      setCurrentCategory,
      mobile_menu,
      selectedLanguage,
      selectLanguage,
      toggleDropdown,
      filteredCountries,
      selectCountry,
      selectedCountry,
      dropdownOpen
    };
  },
});
