import { UseContextReturn } from '~/types/core';
import type { PlaceOrderOutput } from '~/modules/GraphQL/types';
import { ComposableFunctionArgs } from '~/composables';

export const placeOrderCommand = {
  execute: async (context: UseContextReturn, cartId: string, params?: ComposableFunctionArgs<{}>): Promise<PlaceOrderOutput | null> => {
    const  customHeaders = {
      'store':context.app.i18n.locale,
      ...params?.customHeaders,
    }
    // const { data } = await context.app.$vsf.$magento.api.placeOrder(
    //   { cart_id: cartId },
    //   params?.customQuery ?? null,
    //   customHeaders
    // );

    const {data} = await context.app.$vsf.$magento.api.customMutation({
      mutation:`
  mutation placeOrder($input: PlaceOrderInput) {
    placeOrder(input: $input) {
      order {
        order_number
      }
    }
  }
`,
mutationVariables:{
  input : {
    cart_id: cartId
  }
},
customHeaders:customHeaders
    }) as any

    return data?.placeOrder ?? null;
  },
};
