


























































































import { SfBottomNavigation, SfCircleIcon } from '@storefront-ui/vue';
import { defineComponent, useRouter, useContext, computed } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables/useUiState';
import { useUser } from '~/modules/customer/composables/useUser';
import SvgImage from '~/components/General/SvgImage.vue';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { useCart, useUiNotification } from '~/composables';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';

const MobileCategorySidebar = () => import('~/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue');

export default defineComponent({
  name: 'BottomNavigation',
  components: {
    SfBottomNavigation,
    SfCircleIcon,
    MobileCategorySidebar,
    SvgImage,
  },
  setup() {
    const {
      toggleLoginModal,
      toggleMobileMenu,
      isMobileMenuOpen,
    } = useUiState();
    const { isAuthenticated } = useUser();
    const router = useRouter();
    const { app } = useContext();
  const { send: sendNotification } = useUiNotification();
  const {cart} = useCart();
  const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(
      () => wishlistStore.wishlist?.items_count ?? 0
    );
    const handleHomeClick = async () => {
      const homePath = app.localeRoute({ name: 'home' });
      await router.push(homePath);
      if (isMobileMenuOpen.value) {
        toggleMobileMenu();
      }
    };

    const handleAccountClick =  () => {
      if (isAuthenticated.value) {
         router.push(app.localeRoute({ name: 'customer' }));
      } else {
         router.push(app.localeRoute({ name: 'signin' }));
      }
    };
    const toggleWishlistSidebar =  () => {
       if(isAuthenticated.value){
        router.push(app.localeRoute({ name: 'customer-my-wishlist' }));
        console.log("Clicked on Wish navigate")
       } else {
        console.log("Clicked on Wish")
        sendNotification({
          icon: 'warning',
          id: Symbol(`adding wish list error`),
          message: app.i18n.t('You are not authorized, please log in.') as string,
          persist: false,
          title: 'Wish warning',
          type: 'warning',
        });
       }
      
    }
    const toggleCartSidebar =  () => {
      if(isAuthenticated.value) {
        router.push(app.localeRoute({ name: 'cart' }));

      } else {
        router.push(app.localeRoute({ name: 'cart' }));
      }
      
    }

    const loadCategoryMenu = async () => {
      const categories = useCategoryStore();
      if (categories.categories === null) {
        await categories.load();
      }
      toggleMobileMenu();
    };

    return {
      isAuthenticated,
      isMobileMenuOpen,
      toggleWishlistSidebar,
      toggleCartSidebar,
      toggleMobileMenu,
      loadCategoryMenu,
      handleAccountClick,
      handleHomeClick,
      wishlistItemsQty,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),

    };
  },
});
