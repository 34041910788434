import { CustomQuery, UseContextReturn, CustomHeaders } from '~/types/core';
import type { AvailablePaymentMethod } from '~/modules/GraphQL/types';

export const getAvailablePaymentMethodsCommand = {
  execute: async (
    context: UseContextReturn,
    cartId: string,
    customQuery?: CustomQuery,
    customHeaders?: CustomHeaders,
  ): Promise<AvailablePaymentMethod[]> => {
      
    // const { data } = await context.app.$vsf.$magento.api.getAvailablePaymentMethods(cartId, customQuery, customHeaders);
    const {data} =await context.app.$vsf.$magento.api.customQuery({
      query:`
  query GuestAvailablePaymentMethods($cartId: String!) {
    cart(cart_id: $cartId) {
      available_payment_methods {
        code
        title
      }
    }
  }
`,
queryVariables:{
  cartId
},
customHeaders:customHeaders

    }) as any
    return data?.cart?.available_payment_methods ?? [];
  },
};
