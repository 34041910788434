//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from "@nuxtjs/composition-api";

export default defineComponent({
  name: "ConfirmationDialog",
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["confirm", "cancel"],
  setup(props, { emit }) {
    const confirmDelete = () => {
      emit("confirm");
    };

    const cancelDelete = () => {
      emit("cancel");
    };
    const outsideClick = (e) => {
      e.stopPropagation();
      emit("cancel");
    }

    return {
      confirmDelete,
      cancelDelete,
      outsideClick
    };
  },
});
