import locale77427e78 from '../../lang/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"ksa_en","numberFormats":{"default":{"currency":{"style":"currency","currency":"SAR","currencyDisplay":"SAR"}},"ksa_en":{"currency":{"style":"currency","currency":"SAR","currencyDisplay":"SAR"}},"ksa_ar":{"currency":{"style":"currency","currency":"SAR","currencyDisplay":"SAR"}},"uae_ar":{"currency":{"style":"currency","currency":"AED","currencyDisplay":"AED"}},"uae_en":{"currency":{"style":"currency","currency":"AED","currencyDisplay":"AED"}},"omn_ar":{"currency":{"style":"currency","currency":"OMR","currencyDisplay":"OMR"}},"omn_en":{"currency":{"style":"currency","currency":"OMR","currencyDisplay":"OMR"}},"bhr_ar":{"currency":{"style":"currency","currency":"BHD","currencyDisplay":"BHD"}},"bhr_en":{"currency":{"style":"currency","currency":"BHD","currencyDisplay":"BHD"}}}},
  vueI18nLoader: false,
  locales: [{"code":"ksa_en","file":"en.js","iso":"en_US","defaultCurrency":"SAR"},{"code":"ksa_ar","file":"ar.js","iso":"en_US","defaultCurrency":"SAR"},{"code":"uae_en","file":"en.js","iso":"en_US","defaultCurrency":"AED"},{"code":"uae_ar","file":"ar.js","iso":"en_US","defaultCurrency":"AED"},{"code":"omn_en","file":"en.js","iso":"en_US","defaultCurrency":"OMR"},{"code":"omn_ar","file":"ar.js","iso":"en_US","defaultCurrency":"OMR"},{"code":"bhr_en","file":"en.js","iso":"en_US","defaultCurrency":"BHD"},{"code":"bhr_ar","file":"ar.js","iso":"en_US","defaultCurrency":"BHD"}],
  defaultLocale: "ksa_en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/var/www/domains/bedquarter.com/public_html/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: true,
  baseUrl: "http://localhost:3001",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "KSA",
  normalizedLocales: [{"code":"ksa_en","file":"en.js","iso":"en_US","defaultCurrency":"SAR"},{"code":"ksa_ar","file":"ar.js","iso":"en_US","defaultCurrency":"SAR"},{"code":"uae_en","file":"en.js","iso":"en_US","defaultCurrency":"AED"},{"code":"uae_ar","file":"ar.js","iso":"en_US","defaultCurrency":"AED"},{"code":"omn_en","file":"en.js","iso":"en_US","defaultCurrency":"OMR"},{"code":"omn_ar","file":"ar.js","iso":"en_US","defaultCurrency":"OMR"},{"code":"bhr_en","file":"en.js","iso":"en_US","defaultCurrency":"BHD"},{"code":"bhr_ar","file":"ar.js","iso":"en_US","defaultCurrency":"BHD"}],
  localeCodes: ["ksa_en","ksa_ar","uae_en","uae_ar","omn_en","omn_ar","bhr_en","bhr_ar"],
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'ar.js': () => import('../../lang/ar.js' /* webpackChunkName: "lang-ar.js" */),
}
