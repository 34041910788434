import { render, staticRenderFns } from "./default.vue?vue&type=template&id=39852bf2&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=39852bf2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconSprite: require('/var/www/domains/bedquarter.com/public_html/components/General/IconSprite.vue').default,Notification: require('/var/www/domains/bedquarter.com/public_html/components/Notification.vue').default,TopBar: require('/var/www/domains/bedquarter.com/public_html/components/TopBar/TopBar.vue').default,AppHeader: require('/var/www/domains/bedquarter.com/public_html/components/AppHeader.vue').default,MobileHeader: require('/var/www/domains/bedquarter.com/public_html/components/MobileHeader.vue').default,BottomNavigation: require('/var/www/domains/bedquarter.com/public_html/components/BottomNavigation.vue').default,AppFooter: require('/var/www/domains/bedquarter.com/public_html/components/AppFooter.vue').default,LoadWhenVisible: require('/var/www/domains/bedquarter.com/public_html/components/utils/LoadWhenVisible.vue').default,Loader: require('/var/www/domains/bedquarter.com/public_html/components/Loader.vue').default,MainPopup: require('/var/www/domains/bedquarter.com/public_html/components/Popup/MainPopup.vue').default})
