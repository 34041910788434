import { ref, computed, useContext } from "@nuxtjs/composition-api";
import { addToCompareList, createCompareListMutation, deleteCompareProductMutation, getCompareListQuery } from "./useCompare.gql";
import useUiNotification from "../useUiNotification";

// Shared reactive state
const loading = ref(false);
const compare_list = ref([]);
const totalCompareCount = ref(0);

export function useCompare() {
    const { app } = useContext();
    const { send: sendNotification } = useUiNotification();

    const addProductToCompareList = async (productId) => {

        const exist_in_compare_list = compare_list.value.some((item) => {


            return item?.product?.id == productId
        });
        if (exist_in_compare_list) {

            sendNotification({
                icon: 'warning',
                id: Symbol(`product-added-to-compare-cart-${productId}`),
                message: "Already added !!",
                persist: false,
                title: 'Compare warning',
                type: 'warning',
            });

            return;
        }

        if(compare_list.value.length >= 3){
            sendNotification({
                icon: 'warning',
                id: Symbol(`product-added-to-compare-cart-${productId}`),
                message: "Compare list is full !!",
                persist: false,
                title: 'Compare warning',
                type: 'warning',
            });
            return;
        }

        loading.value = true;

        const compare_list_id = sessionStorage.getItem("compare_list_id");

        if (compare_list_id) {
            try {
                const response = await app.$vsf.$magento.api.customQuery({
                    query: addToCompareList,
                    queryVariables: {
                        input: {
                            uid: JSON.parse(compare_list_id),
                            products: [productId],
                        },
                    },
                    customHeaders:{
                        'store':app.i18n.locale
                      }
                }) as any;
                if (response?.data?.addProductsToCompareList?.uid) {
                    compare_list.value = response?.data?.addProductsToCompareList?.items;
                    totalCompareCount.value = response?.data?.addProductsToCompareList?.item_count;

                    sendNotification({
                        icon: 'success',
                        id: Symbol(`product-added-to-compare-cart-${productId}`),
                        message: "Successfully added !!",
                        persist: false,
                        title: 'Compare Success',
                        type: 'success',
                    });
                } else {
                    sendNotification({
                        icon: 'error',
                        id: Symbol(`compare cart`),
                        message: "Try again !!",
                        persist: false,
                        title: 'Compare error',
                        type: 'danger',
                    })
                }
            } catch (error) {
                console.error("Error while adding product to compare list", error);
            } finally {
                loading.value = false;
            }
        } else {
            try {
                const response = await app.$vsf.$magento.api.customQuery({
                    query: createCompareListMutation,
                    queryVariables: {
                        input: {
                            products: [productId],
                        },
                    },
                    customHeaders:{
                        'store':app.i18n.locale
                      }
                }) as any;
                if (response?.data?.createCompareList?.uid) {
                    sessionStorage.setItem(
                        "compare_list_id",
                        JSON.stringify(response?.data?.createCompareList?.uid)
                    );
                    compare_list.value = response?.data?.createCompareList?.items;
                    totalCompareCount.value = response?.data?.createCompareList?.item_count;

                    sendNotification({
                        icon: 'success',
                        id: Symbol(`product-added-to-compare-cart-${productId}`),
                        message: "Successfully added !!",
                        persist: false,
                        title: 'Compare Success',
                        type: 'success',
                    });
                } else {
                    sendNotification({
                        icon: 'error',
                        id: Symbol(`compare cart`),
                        message: "Try again !!",
                        persist: false,
                        title: 'Compare error',
                        type: 'danger',
                    })
                }
            } catch (error) {
                console.error("Error while creating compare list", error);
            } finally {
                loading.value = false;
            }
        }
    };

    const loadCompareList = async () => {
        loading.value = true;
        const compare_list_id = sessionStorage.getItem("compare_list_id");
        if (compare_list_id) {
            try {
                const response = await app.$vsf.$magento.api.customQuery({
                    query: getCompareListQuery,
                    queryVariables: {
                        uid: JSON.parse(compare_list_id),
                    },
                    customHeaders:{
                        'store':app.i18n.locale
                      }
                }) as any;
                if (response?.data?.compareList?.items) {
                    compare_list.value = response?.data?.compareList?.items;
                    totalCompareCount.value = response?.data?.compareList?.item_count;
                }
        loading.value = false;

            } catch (error) {
                console.error("Error while loading compare list", error);
        loading.value = false;

            }
        } else {
            loading.value = false;
        }
    };

    const removeProductFromCompareList = async (productId) => {
        loading.value = true;
        const compare_list_id = sessionStorage.getItem("compare_list_id");
        if (compare_list_id) {
            try {
                const response = await app.$vsf.$magento.api.customQuery({
                    query: deleteCompareProductMutation,
                    queryVariables: {
                        input:{
                            uid: JSON.parse(compare_list_id),
                            products: [productId?.toString()],
                        }
                    },
                    customHeaders:{
                        'store':app.i18n.locale
                      }
                }) as any;
                if (response?.data?.removeProductsFromCompareList?.items) {
                    compare_list.value = response?.data?.removeProductsFromCompareList?.items;
                    totalCompareCount.value = response?.data?.removeProductsFromCompareList?.item_count;
                    loading.value = false;
                    sendNotification({
                        icon: 'success',
                        id: Symbol(`product-added-to-compare-cart-${productId}`),
                        message: "Removed Successfully !!",
                        persist: false,
                        title: 'Compare warning',
                        type: 'success',
                    });
                loading.value = false;

                } else {
                    sendNotification({
                        icon: 'warning',
                        id: Symbol(`product-added-to-compare-cart-${productId}`),
                        message: "Try again !!",
                        persist: false,
                        title: 'Compare warning',
                        type: 'warning',
                    });
                loading.value = false;

                }
            } catch (error) {
                loading.value = false;
                sendNotification({
                    icon: 'warning',
                    id: Symbol(`product-added-to-compare-cart-${productId}`),
                    message: "Try again !!",
                    persist: false,
                    title: 'Compare warning',
                    type: 'warning',
                });
                console.error("Error while loading compare list", error);
            }
        }
    };

    const clearCompare = async () => {
        const compare_list_id = sessionStorage.getItem("compare_list_id");
    
        if (compare_list_id) {
            loading.value = true;
    
            const removeItems = await app.$vsf.$magento.api.customMutation({
                mutation: `
                mutation deleteCompareList($uid: ID!) {
                    deleteCompareList(
                        uid: $uid
                    ) {
                        result
                    }
                }
                `,
                mutationVariables: {
                    uid: JSON.parse(compare_list_id),
                },
                customHeaders: {
                    'store': app.i18n.locale,
                }
            }) as any;
    
            if (removeItems?.data?.deleteCompareList?.result) {
                compare_list.value = [];
                totalCompareCount.value = 0;
                sessionStorage.removeItem("compare_list_id");
                sendNotification({
                    icon: 'success',
                    id: Symbol(`compare cart get clear`),
                    message: "Clear Successfully !!",
                    persist: false,
                    title: 'Compare Clear',
                    type: 'success',
                });
                loading.value = false;
    
            } else {
                sendNotification({
                    icon: 'warning',
                    id: Symbol(`compare cart get clear`),
                    message: "Try again !!",
                    persist: false,
                    title: 'Compare Clear',
                    type: 'warning',
                });
                loading.value = false;
            }
        } else {
            sendNotification({
                icon: 'warning',
                id: Symbol(`compare cart get clear`),
                message: "Try again !!",
                persist: false,
                title: 'Compare Clear',
                type: 'warning',
            });
            loading.value = false;
        }
    };
    

    return {
        addProductToCompareList,
        loadCompareList,
        removeProductFromCompareList,
        loading: computed(() => loading.value),
        compare_list: computed(() => compare_list.value),
        totalCompareCount: computed(() => totalCompareCount.value),
        clearCompare
    };
}

export default useCompare;
