export const AddToWishlist = () => import('../../components/AddToWishlist.vue' /* webpackChunkName: "components/add-to-wishlist" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const BottomNavigation = () => import('../../components/BottomNavigation.vue' /* webpackChunkName: "components/bottom-navigation" */).then(c => wrapFunctional(c.default || c))
export const CallToAction = () => import('../../components/CallToAction.vue' /* webpackChunkName: "components/call-to-action" */).then(c => wrapFunctional(c.default || c))
export const ConfirmationDialog = () => import('../../components/ConfirmationDialog.vue' /* webpackChunkName: "components/confirmation-dialog" */).then(c => wrapFunctional(c.default || c))
export const ContentBlock = () => import('../../components/ContentBlock.vue' /* webpackChunkName: "components/content-block" */).then(c => wrapFunctional(c.default || c))
export const ContentBlocks = () => import('../../components/ContentBlocks.vue' /* webpackChunkName: "components/content-blocks" */).then(c => wrapFunctional(c.default || c))
export const CouponCode = () => import('../../components/CouponCode.vue' /* webpackChunkName: "components/coupon-code" */).then(c => wrapFunctional(c.default || c))
export const CurrencySelector = () => import('../../components/CurrencySelector.vue' /* webpackChunkName: "components/currency-selector" */).then(c => wrapFunctional(c.default || c))
export const Faq = () => import('../../components/Faq.vue' /* webpackChunkName: "components/faq" */).then(c => wrapFunctional(c.default || c))
export const HTMLContent = () => import('../../components/HTMLContent.vue' /* webpackChunkName: "components/h-t-m-l-content" */).then(c => wrapFunctional(c.default || c))
export const HeaderLogo = () => import('../../components/HeaderLogo.vue' /* webpackChunkName: "components/header-logo" */).then(c => wrapFunctional(c.default || c))
export const HeroSection = () => import('../../components/HeroSection.vue' /* webpackChunkName: "components/hero-section" */).then(c => wrapFunctional(c.default || c))
export const InstagramFeed = () => import('../../components/InstagramFeed.vue' /* webpackChunkName: "components/instagram-feed" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const LoadingBar = () => import('../../components/LoadingBar.vue' /* webpackChunkName: "components/loading-bar" */).then(c => wrapFunctional(c.default || c))
export const MobileHeader = () => import('../../components/MobileHeader.vue' /* webpackChunkName: "components/mobile-header" */).then(c => wrapFunctional(c.default || c))
export const MobileStoreBanner = () => import('../../components/MobileStoreBanner.vue' /* webpackChunkName: "components/mobile-store-banner" */).then(c => wrapFunctional(c.default || c))
export const NewProducts = () => import('../../components/NewProducts.vue' /* webpackChunkName: "components/new-products" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const PagesHeader = () => import('../../components/PagesHeader.vue' /* webpackChunkName: "components/pages-header" */).then(c => wrapFunctional(c.default || c))
export const StoreSwitcher = () => import('../../components/StoreSwitcher.vue' /* webpackChunkName: "components/store-switcher" */).then(c => wrapFunctional(c.default || c))
export const UserAddressDetails = () => import('../../components/UserAddressDetails.vue' /* webpackChunkName: "components/user-address-details" */).then(c => wrapFunctional(c.default || c))
export const FaqAccordian = () => import('../../components/Accordians/FaqAccordian.vue' /* webpackChunkName: "components/faq-accordian" */).then(c => wrapFunctional(c.default || c))
export const BrandsSlider = () => import('../../components/BrandsSlider/BrandsSlider.vue' /* webpackChunkName: "components/brands-slider" */).then(c => wrapFunctional(c.default || c))
export const BrandCard = () => import('../../components/Cards/BrandCard.vue' /* webpackChunkName: "components/brand-card" */).then(c => wrapFunctional(c.default || c))
export const HomePageCTA = () => import('../../components/Cards/HomePageCTA.vue' /* webpackChunkName: "components/home-page-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const HomePageIconsBlockCard = () => import('../../components/Cards/HomePageIconsBlockCard.vue' /* webpackChunkName: "components/home-page-icons-block-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/Cards/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductListCard = () => import('../../components/Cards/ProductListCard.vue' /* webpackChunkName: "components/product-list-card" */).then(c => wrapFunctional(c.default || c))
export const ProductListingSkeleton = () => import('../../components/Cards/ProductListingSkeleton.vue' /* webpackChunkName: "components/product-listing-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ReviewCard = () => import('../../components/Cards/ReviewCard.vue' /* webpackChunkName: "components/review-card" */).then(c => wrapFunctional(c.default || c))
export const ShopByCategoryBlockCards = () => import('../../components/Cards/ShopByCategoryBlockCards.vue' /* webpackChunkName: "components/shop-by-category-block-cards" */).then(c => wrapFunctional(c.default || c))
export const SubCategoryBlockCards = () => import('../../components/Cards/SubCategoryBlockCards.vue' /* webpackChunkName: "components/sub-category-block-cards" */).then(c => wrapFunctional(c.default || c))
export const ContactSection = () => import('../../components/ContactSectionUI/ContactSection.vue' /* webpackChunkName: "components/contact-section" */).then(c => wrapFunctional(c.default || c))
export const CurrenciesModal = () => import('../../components/CurrencySelector/CurrenciesModal.vue' /* webpackChunkName: "components/currencies-modal" */).then(c => wrapFunctional(c.default || c))
export const ContactUsForm = () => import('../../components/Forms/ContactUsForm.vue' /* webpackChunkName: "components/contact-us-form" */).then(c => wrapFunctional(c.default || c))
export const IconSprite = () => import('../../components/General/IconSprite.vue' /* webpackChunkName: "components/icon-sprite" */).then(c => wrapFunctional(c.default || c))
export const SvgImage = () => import('../../components/General/SvgImage.vue' /* webpackChunkName: "components/svg-image" */).then(c => wrapFunctional(c.default || c))
export const LatestProductSlider = () => import('../../components/LatestProductSlider/LatestProductSlider.vue' /* webpackChunkName: "components/latest-product-slider" */).then(c => wrapFunctional(c.default || c))
export const MainSlider = () => import('../../components/MainSlider/MainSlider.vue' /* webpackChunkName: "components/main-slider" */).then(c => wrapFunctional(c.default || c))
export const MainSliderCard = () => import('../../components/MainSlider/MainSliderCard.vue' /* webpackChunkName: "components/main-slider-card" */).then(c => wrapFunctional(c.default || c))
export const SignInModal = () => import('../../components/Modal/SignInModal.vue' /* webpackChunkName: "components/sign-in-modal" */).then(c => wrapFunctional(c.default || c))
export const MainPopup = () => import('../../components/Popup/MainPopup.vue' /* webpackChunkName: "components/main-popup" */).then(c => wrapFunctional(c.default || c))
export const ProductSlider = () => import('../../components/ProductSlider/ProductSlider.vue' /* webpackChunkName: "components/product-slider" */).then(c => wrapFunctional(c.default || c))
export const TabbyPromo = () => import('../../components/PromoComponents/TabbyPromo.vue' /* webpackChunkName: "components/tabby-promo" */).then(c => wrapFunctional(c.default || c))
export const TamaraPromo = () => import('../../components/PromoComponents/TamaraPromo.vue' /* webpackChunkName: "components/tamara-promo" */).then(c => wrapFunctional(c.default || c))
export const SkeletonLoader = () => import('../../components/SkeletonLoader/index.vue' /* webpackChunkName: "components/skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const StoresModal = () => import('../../components/StoreSwitcher/StoresModal.vue' /* webpackChunkName: "components/stores-modal" */).then(c => wrapFunctional(c.default || c))
export const TopBar = () => import('../../components/TopBar/TopBar.vue' /* webpackChunkName: "components/top-bar" */).then(c => wrapFunctional(c.default || c))
export const CheckStoresAndCurrencyGql = () => import('../../components/TopBar/checkStoresAndCurrency.gql.ts' /* webpackChunkName: "components/check-stores-and-currency-gql" */).then(c => wrapFunctional(c.default || c))
export const SuggestedSearchGql = () => import('../../components/TopBar/suggestedSearch.gql.ts' /* webpackChunkName: "components/suggested-search-gql" */).then(c => wrapFunctional(c.default || c))
export const UseTopBar = () => import('../../components/TopBar/useTopBar.ts' /* webpackChunkName: "components/use-top-bar" */).then(c => wrapFunctional(c.default || c))
export const WorldBrandSlider = () => import('../../components/WorldBrandSlider/WorldBrandSlider.vue' /* webpackChunkName: "components/world-brand-slider" */).then(c => wrapFunctional(c.default || c))
export const CategoryDropdownComponent = () => import('../../components/dropdowncomponent/CategoryDropdownComponent.vue' /* webpackChunkName: "components/category-dropdown-component" */).then(c => wrapFunctional(c.default || c))
export const LoadWhenVisible = () => import('../../components/utils/LoadWhenVisible.vue' /* webpackChunkName: "components/load-when-visible" */).then(c => wrapFunctional(c.default || c))
export const CurrencyDataMock = () => import('../../components/CurrencySelector/__tests__/currencyData.mock.ts' /* webpackChunkName: "components/currency-data-mock" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigation = () => import('../../components/Header/Navigation/HeaderNavigation.vue' /* webpackChunkName: "components/header-navigation" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigationItem = () => import('../../components/Header/Navigation/HeaderNavigationItem.vue' /* webpackChunkName: "components/header-navigation-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigationSubcategories = () => import('../../components/Header/Navigation/HeaderNavigationSubcategories.vue' /* webpackChunkName: "components/header-navigation-subcategories" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/Header/SearchBar/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchResults = () => import('../../components/Header/SearchBar/SearchResults.vue' /* webpackChunkName: "components/search-results" */).then(c => wrapFunctional(c.default || c))
export const AvailableStoresMock = () => import('../../components/StoreSwitcher/__tests__/availableStores.mock.ts' /* webpackChunkName: "components/available-stores-mock" */).then(c => wrapFunctional(c.default || c))
export const ClickOutsideDirective = () => import('../../components/directives/click-outside/click-outside-directive.ts' /* webpackChunkName: "components/click-outside-directive" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
