export const homePageIdentifier = (path) => {
    if(path === 'ksa_en') {
        return 'home'
    } else if(path === 'ksa_ar'){
        return 'ksa-arabic-homepage'
    }else if(path === 'uae_en'){
        return 'uae-home-page'
    }else if(path === 'uae_ar'){
        return 'uae-arabic-homepage'
    }else if(path === 'omn_en'){
        return 'omn-home-page'
    }else if(path === 'omn_ar'){
        return 'omn-arabic-homepage'
    }else if(path === 'bhr_en'){
        return 'bhr-home-page'
    }else if(path === 'bhr_ar'){
        return 'bhr-arabic-homepage'
    }
}

export const homePageCTABlockIdentifier = (path) => {
  if(path === 'ksa_en') {
      return 'home-page-cta-ksa'
  } else if(path === 'ksa_ar'){
      return 'home-page-cta-ksa-ar'
  }else if(path === 'uae_en'){
      return 'home-page-cta-uae'
  }else if(path === 'uae_ar'){
      return 'home-page-cta-uae-ar'
  }else if(path === 'omn_en'){
      return 'home-page-cta-omn'
  }else if(path === 'omn_ar'){
      return 'home-page-cta-omn-ar'
  }else if(path === 'bhr_en'){
      return 'home-page-cta-bhr'
  }else if(path === 'bhr_ar'){
      return 'home-page-cta-bhr-ar'
  }
}

export const homePageTitle = (path) => {
    if(path === 'ksa_en') {
        return 'Home Page'
    } else if(path === 'ksa_ar'){
        return 'Home Page'
    }else if(path === 'uae_en'){
        return 'UAE Home Page'
    }else if(path === 'uae_ar'){
        return 'UAE Home Page'
    }else if(path === 'omn_en'){
        return 'Oman Home Page'
    }else if(path === 'omn_ar'){
        return 'Oman Home Page'
    }else if(path === 'bhr_en'){
        return 'Bahrain Home Page'
    }else if(path === 'bhr_ar'){
        return 'Bahrain Home Page'
    }
}

export const languageSwitcher = (path) => {
    if(path === 'ksa_en') {
        return 'ksa_ar'
    } else if(path === 'ksa_ar'){
        return 'ksa_en'
    }else if(path === 'uae_en'){
        return 'uae_ar'
    }else if(path === 'uae_ar'){
        return 'uae_en'
    }else if(path === 'omn_en'){
        return 'omn_ar'
    }else if(path === 'omn_ar'){
        return 'omn_en'
    }else if(path === 'bhr_en'){
        return 'bhr_ar'
    }else if(path === 'bhr_ar'){
        return 'bhr_en'
    }
}

export const countryCodeMap = {
    "Saudi Arabia": { "English": "ksa_en", "Arabic": "ksa_ar" },
    "Oman": { "English": "omn_en", "Arabic": "omn_ar" },
    "Bahrain": { "English": "bhr_en", "Arabic": "bhr_ar" },
    "United Arab Emirates": { "English": "uae_en", "Arabic": "uae_ar" }
};

export const footer_identifier = [
    "top_categories",
    "About -us-links",
    "help",
    "follow-us",
    "newsletter",
  ]

export const footer_static_blocks = (locale) => {
  if(locale == 'ksa_en'){
    return ['footer_top_categories','footer_about_us','footer_help']
  } else if(locale == 'ksa_ar'){
    return ['footer_top_categories_ksa_ar','footer_about_us_ksa_ar','footer_help_ksa_ar']
  }else if(locale == 'uae_en'){
    return ['footer_top_categories_uae_en','footer_about_us_uae_en','footer_help_uae_en']
  } else if(locale == 'uae_ar'){
    return ['footer_top_categories_uae_ar','footer_about_us_uae_ar','footer_help_uae_ar']
  }else if(locale == 'omn_en'){
    return ['footer_top_categories_omn_en','footer_about_us_omn_en','footer_help_omn_en']
  } else if(locale == 'omn_ar'){
    return ['footer_top_categories_omn_ar','footer_about_us_omn_ar','footer_help_omn_ar']
  }else if(locale == 'bhr_en'){
    return ['footer_top_categories_bhr_en','footer_about_us_bhr_en','footer_help_bhr_en']
  } else if(locale == 'bhr_ar'){
    return ['footer_top_categories_bhr_ar','footer_about_us_bhr_ar','footer_help_bhr_ar']
  }
}

export const main_slider_ids = (locale) => {
  if(locale == 'ksa_en'){
    return "1";
  }else if(locale == 'ksa_ar'){
    return "2";
  }else if(locale == 'uae_en'){
    return "5";
  }else if(locale == 'uae_ar'){
    return "6";
  }else if(locale == 'omn_en'){
    return "7";
  }else if(locale == 'omn_ar'){
    return "8";
  }else if(locale == 'bhr_en'){
    return "3";
  }else if(locale == 'bhr_ar'){
    return "4";
  }
}


export const footer_ar_identifier = [
    "top-categories-ar",
    "about-ar",
    "help-ar",
    "follow-ar",
    "newsletter-ar"
  ]



export function replacePartInURL(currentURL, oldPart, newPart) {
    const url = new URL(currentURL, window.location.origin);
    const pathSegments = url.pathname.split('/');

    // Replace the old part with the new part
    const updatedSegments = pathSegments.map(segment => 
        segment === oldPart ? newPart : segment
    );

    // Update the URL
    url.pathname = updatedSegments.join('/');
    return url.toString();
}


export const determineStore = (path) => {
    if(path == 'uae_en' || path == 'uae_ar'){
       return {
          name: 'United Arab Emirates',
          flag: require("~/assets/images/uae.svg")
        }
        
      }else if(path == 'ksa_en' || path == 'ksa_ar'){ 
        return {
          name: 'Saudi Arabia',
          flag: require("~/assets/images/saudi-arab.svg")
        }
      } else if(path == 'omn_en' || path == 'omn_ar'){
        return {
          name: 'Oman',
          flag: require("~/assets/images/oman.svg")
        }
      } else if(path == 'bhr_en' || path == 'bhr_ar'){
        return {
          name: 'Bahrain',
          flag: require("~/assets/images/bahrain.svg")
        }
      }
}

export const determineCountry = (path) => {
  if(path == 'uae_en' || path == 'uae_ar'){
     return "AE"
    }else if(path == 'ksa_en' || path == 'ksa_ar'){ 
      return "SA"
    } else if(path == 'omn_en' || path == 'omn_ar'){
      return "OM"
    } else if(path == 'bhr_en' || path == 'bhr_ar'){
      return "BH"
    }
}

export const determineStoreCountry = (path) => {
  if(path == 'uae_en' || path == 'uae_ar'){
     return "UAE"
    }else if(path == 'ksa_en' || path == 'ksa_ar'){ 
      return "KSA"
    } else if(path == 'omn_en' || path == 'omn_ar'){
      return "OMN"
    } else if(path == 'bhr_en' || path == 'bhr_ar'){
      return "BHR"
    }
}

export const determineMapCenter = (path) => {
  if(path == 'uae_en' || path == 'uae_ar'){
     return {
      lat:25.17406250,
      lng: 55.21637380
     }
    }else if(path == 'ksa_en' || path == 'ksa_ar'){ 
      return {
        lat: 24.80987000,
        lng: 46.62143000
       }
    } else if(path == 'omn_en' || path == 'omn_ar'){
      return {
        lat: 23.59661684,
        lng: 58.43915794
       }
    } else if(path == 'bhr_en' || path == 'bhr_ar'){
      return {
        lat: 26.21008480,
        lng: 50.57214170
       }
    }
}

export const determineHomePageIconsIdentifier = (path) => {
  if(path == 'ksa_en'){
    return 'home-page-icons-ksa'
  }else if(path == 'ksa_ar'){
    return 'home-page-icons-ksa_ar'
  } else if(path == 'uae_en'){
    return 'home-page-icons-uae_en'
  }else if(path == 'uae_ar'){
    return 'home-page-icons-uae_ar'
  }else if(path == 'omn_en'){
    return 'home-page-icons-omn_en'
  }else if(path == 'omn_ar'){
    return 'home-page-icons-omn_ar'
  }else if(path == 'bhr_en'){
    return 'home-page-icons-bhr_en'
  }else if(path == 'bhr_ar'){
    return 'home-page-icons-bhr_ar'
  
  
  
  }
}

export const gatewayAvailibilityCheck = (lang) => {
  const gatewayAvailability = {
    "ksa_en": {
      "Telr": true,
      "Tabby": true,
      "Tamara": true,
      'CashOnDelivery': true
    },
    "ksa_ar": {
      "Telr": true,
      "Tabby": true,
      "Tamara": true,
      'CashOnDelivery': true
    },
    "uae_en": {
      "Telr": true,
      "Tabby": false,
      "Tamara": false,
      'CashOnDelivery': true

    },
    "uae_ar": {
      "Telr": true,
      "Tabby": false,
      "Tamara": false,
      'CashOnDelivery': true

    },
    "bhr_en": {
      "Telr": true,
      "Tabby": false,
      "Tamara": false,
      'CashOnDelivery': true
    },
    "bhr_ar": {
      "Telr": true,
      "Tabby": false,
      "Tamara": false,
      'CashOnDelivery': true

    },
    "omn_en": {
      "Telr": true,
      "Tabby": false,
      "Tamara": false,
      'CashOnDelivery': true

    },
    "omn_ar": {
      "Telr": true,
      "Tabby": false,
      "Tamara": false,
      'CashOnDelivery': true

    }
  };

  if (!gatewayAvailability[lang]) {
    return "Invalid country-language combination.";
  }

  const availableGateways = Object.keys(gatewayAvailability[lang])
    .filter(gateway => gatewayAvailability[lang][gateway]) 
    .map(gateway => gateway); 

  
  if (availableGateways.length === 0) {
    return "No payment gateways available.";
  }

  return availableGateways;
}

export const availableStoreCountries = [
    {
      name: "United Arab Emirates",
      flag: require("~/assets/images/uae.svg")
    },
    {
      name: "Oman",
      flag:require("~/assets/images/oman.svg")
    },
    {
      name: "Bahrain",
      flag: require("~/assets/images/bahrain.svg")
    },
    {
      name: "Saudi Arabia",
      flag: require("~/assets/images/saudi-arab.svg")
    },
  ];

export const showingTamaraWidget = (lang) => {
  if(lang == 'ksa_en' || lang == 'ksa_ar'){
    return true
  }
  else if(lang == 'uae_en' || lang == 'uae_ar'){
    return false
  } else if(lang == 'omn_en' || lang == 'omn_ar'){
    return false
  } else if(lang == 'bhr_en' || lang == 'bhr_ar'){
    return false
  }
}

export const storeWiseCurrency = (store) => {
  if(store == 'ksa_en' || store == 'ksa_ar'){
    return 'SAR'
  }
  else if(store == 'uae_en' || store == 'uae_ar'){
    return 'AED'
  } else if(store == 'omn_en' || store == 'omn_ar'){
    return 'OMR'
  } else if(store == 'bhr_en' || store == 'bhr_ar'){
    return 'BHD'
  }
}

export const showingTabbyWidget = (lang) => {
  if(lang == 'ksa_en' || lang == 'ksa_ar'){
    return true
  }
  else if(lang == 'uae_en' || lang == 'uae_ar'){
    return false
  } else if(lang == 'omn_en' || lang == 'omn_ar'){
    return false
  } else if(lang == 'bhr_en' || lang == 'bhr_ar'){
    return false
  }
}

  export const determineLanguage = (locale) => {
    const parts = locale.split('_');
    if (parts.length === 2) {
      const lang = parts[1].toLowerCase();
      if (lang === 'en' ) {
        return {
            name:"عربي",
            code:'ar'
        };
      } else {
       
        return {
            name:"إنجليزي",
            code:'en'
        };
      }
    }
    return {
        name:"إنجليزي",
        code:'en'
    };
  };


  export const cms_pages = [
    {
      name:"Contact us",
      path:'contact-us',
    },
    {
      name:"FAQ",
      path:'faq'
    },
    {
      name:"Shipping",
      path:"shipping-policy"
    },
    {
      name:"Return and Refund Policy",
      path:'return-and-refund-policy'
    },
    {
      name: "Terms and Conditions",
      path:"terms-and-conditions"
    },
    {
      name:"Privacy Policy",
      path:"privacy-policy-cookie-restriction-mode"
    }
  ]

  export const about_cms_pages = [
    {
      name:"About",
      url:[
        {
          name:"LA-Z-BOY",
          path:'la-z-boy',
        },
        {
          name:"OSIM",
          path:'osim'
        },
        {
          name:"Hefel",
          path:"hefel-hotel"
        },
        {
          name:"Tempur",
          path:'tempur'
        },
        {
          name: "Mühldorfer",
          path:"muhldorfer"
        },
      ]
    },
    {
      name:"About Maz Holding Group",
      path:"about-maz-holding-group"
    }
  ]

